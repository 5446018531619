import React from "react"
import PropTypes from "prop-types"
class Recapitulatif extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            certify: false
        };
    }

    render() {
        return ( 
            <React.Fragment >
                <div className = "ml-4 mb-4 custom-control custom-checkbox" >
                    <input type = "checkbox" className = "custom-control-input" checked = { this.state.certify } id = "certification" onChange = { event => this.setState({ certify: event.target.checked }) } required={true} />
                    <label className = "custom-control-label" htmlFor = "certification"> Je certifie sur l 'honneur l'exactitude de cette déclaration. </label>
                    <div className = "invalid-feedback"> Vous devez certifie les information avant de les enregistrer </div>
                </div>

                <div className = "row form-group m-auto" style={{ width: 21 + 'cm' }}>
                    <div className = "col-md-4">
                        <a href={ `/demandes/recapitulatif/${this.props.hashid}.pdf`} target = "blank" className = "btn btn-secondary mr-1">
                            Télécharger <i className = "fa fa-download"></i>
                        </a>
                    </div>
                    <div className = "col-md-8 text-right" >
                        <a href = { `/demandes/le-projet/${this.props.hashid}` } className = "btn btn-primary btn-lg mr-1" >
                            <i className = "fa fa-arrow-left" > </i> Précedent
                        </a>
                        {
                            this.props.is_mofier &&
                                <button type="submit" className="btn btn-secondary btn-lg" >
                                    <i className = "fa fa-check"> </i> Valider
                                </button>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Recapitulatif