import React from "react"
import PropTypes from "prop-types"

import { getRegionsAndCodes, getPrefecturesAndCodesByRegionCode, getSousprefecturesByPrefectureCode } from 'guinea-subdivision';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

class Adresse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pays_actuel: this.props.paysActuel || 'Guinée',
      region_actuel: this.props.regionActuel,
      prefecture_actuel: this.props.prefectActuel,
      s_prefecture_actuel: this.props.sPrefectActuel,
      ville_actuel: this.props.villeActuel,
      quartier_actuel: this.props.quartierActuel || '',
      secteur_actuel: this.props.secteurActuel || '',
      selcted_reg_code: this.props.code_reg || '',
      selcted_pref_code: this.props.code_pref || '',
      regions: getRegionsAndCodes(),
      prefectures: this.props.code_reg !== ''? getPrefecturesAndCodesByRegionCode(this.props.code_reg) : [],
      s_prefectures: this.props.code_pref !== ''? getSousprefecturesByPrefectureCode(this.props.code_pref) : []
    };

    this.paysActuelChange = this.paysActuelChange.bind(this);
    this.selctRegionActuelChange = this.selctRegionActuelChange.bind(this);
    this.selctPrefectureActuelChange = this.selctPrefectureActuelChange.bind(this);
  }
  

  paysActuelChange(event) {
    this.setState({pays_actuel: event.target.value});
    if (event.target.value === 'Guinée') {
      this.setState({
        regions: getRegionsAndCodes(),
        prefectures: [],
        s_prefectures: [],
        selcted_reg_code: '',
        selcted_pref_code: '',
        region_actuel: '',
        prefecture_actuel: '',
        s_prefecture_actuel: '',
        ville_actuel: "",
      })
    } else {
      this.setState({
        regions: [],
        prefectures: [],
        s_prefectures: [],
        selcted_reg_code: '',
        selcted_pref_code: '',
        region_actuel: '',
        prefecture_actuel: '',
        s_prefecture_actuel: '',
        ville_actuel: "",
      })
    }
  }

  selctRegionActuelChange(event) {
    this.setState({region_actuel: event.target.value});
    const code = event.target.options[event.target.selectedIndex].dataset.code
    this.setState({
      prefectures: getPrefecturesAndCodesByRegionCode(code),
      selcted_reg_code: code,
      s_prefectures: [],
      selcted_pref_code: '',
      prefecture_actuel: '',
      s_prefecture_actuel: '',
      ville_actuel: '',
    })
  }

  selctPrefectureActuelChange(event) {
    this.setState({prefecture_actuel: event.target.value});
    const pcode = event.target.options[event.target.selectedIndex].dataset.pcode
    this.setState({
      s_prefectures: getSousprefecturesByPrefectureCode(pcode),
      selcted_pref_code: pcode,
      s_prefecture_actuel: '',
      ville_actuel: '',
    })
  }

  render () {
    const { pays_actuel, region_actuel, prefecture_actuel, s_prefecture_actuel, ville_actuel,quartier_actuel, secteur_actuel, regions, prefectures, s_prefectures, selcted_reg_code, selcted_pref_code } = this.state
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="pays_actuel">Pays * </label>
              <select className="form-control" name="pays_actuel" defaultValue={pays_actuel} required={true} onChange={this.paysActuelChange}>
                <option value="" >Sélectionner un pays</option>
                {
                  this.props.pays.map(p => (
                    <option key={`pays_actuel_${p.id}`} value={p.nom}>{p.nom}</option>
                  ))
                }
              </select>
              <div className="invalid-feedback">Le pays est obligatoire.</div>
            </div>
          </div>
          <div className="col-sm-6">
            {
              pays_actuel !== 'Guinée'?
              <div className="form-group">
                <label htmlFor="email">Ville * </label>
                <input type='text' value={ville_actuel} className='form-control' name='demande_logement[ville_actuel]' required={true} onChange={event => this.setState({ville_actuel: event.target.value})}/>
                <div className="invalid-feedback">La ville est obligatoire.</div>
              </div>:
              <div className="form-group">
                <label htmlFor="email">Région * </label>
                <input type='hidden' name="code_reg" value={selcted_reg_code}/>
                <select className="form-control" name="region_actuel" defaultValue={region_actuel} required={true} onChange={this.selctRegionActuelChange}>
                  <option value="" >Sélectionner une region</option>
                  {
                    regions.map(r => (
                      <option key={`region_${r.code}`} value={r.region} data-code={r.code}>{r.region}</option>
                    ))
                  }
                </select>
                <div className="invalid-feedback">La région est obligatoire.</div>
              </div>
            }
          </div>
        </div>
        {
          pays_actuel === 'Guinée' &&
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Préfecture * </label>
                <input type='hidden' name="code_pref" value={selcted_pref_code}/>
                <select className="form-control" name="prefecture_actuel" defaultValue={prefecture_actuel} required={true} onChange={this.selctPrefectureActuelChange}>
                  <option value="" >Sélectionner une préfecture</option>
                  {
                    prefectures.map(p => (
                      <option key={`prefect_${p.prefectureCode}`} value={p.prefecture} data-pcode={p.prefectureCode}>{p.prefecture}</option>
                    ))
                  }
                </select>
                <div className="invalid-feedback">La préfecture est obligatoire.</div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {
                  prefecture_actuel === 'Conakry' &&
                  <label htmlFor="email">Commune * </label>
                }
                {
                  prefecture_actuel !== 'Conakry' &&
                  <label htmlFor="email">Sous-Préfecture * </label>
                }
                <select className="form-control" name="demande_logement[sous_prefecture_actuel]" defaultValue={s_prefecture_actuel} required={true} onChange={event => this.setState({s_prefecture_actuel: event.target.value})}>
                  <option value="" >Sélectionner une commune ou sous préfecture</option>
                  {
                    s_prefectures.map(sp => (
                      <option key={`sprefect_${sp}`} value={capitalize(sp)}>{capitalize(sp)}</option>
                    ))
                  }
                </select>
                <div className="invalid-feedback">La sous préfecture est obligatoire.</div>
              </div>
            </div>
          </div>
        }
        {
          pays_actuel === 'Guinée' &&
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Quartier * </label>
                <input type='text' value={quartier_actuel} className='form-control' name='demande_logement[quartier_actuel]' required={true} onChange={event => this.setState({quartier_actuel: event.target.value})}/>
                <div className="invalid-feedback">Le quartier est obligatoire.</div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Secteur </label>
                <input type='text' value={secteur_actuel} className='form-control' name='demande_logement[secteur_actuel]' onChange={event => this.setState({secteur_actuel: event.target.value})}/>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

Adresse.propTypes = {
  paysActuel: PropTypes.string,
  regionActuel: PropTypes.string,
  prefectActuel: PropTypes.string,
  sPrefectActuel: PropTypes.string,
  villeActuel: PropTypes.string,
  pays: PropTypes.any,
  code_reg: PropTypes.string,
  code_pref: PropTypes.string
};
export default Adresse
