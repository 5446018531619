import React from "react"
import PropTypes from "prop-types"
import { getRegionsAndCodes, getPrefecturesAndCodesByRegionCode, getSousprefecturesByPrefectureCode } from 'guinea-subdivision';

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

class Construction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nom: this.props.nom || '',
            region: this.props.region,
            prefecture: this.props.prefecture,
            sous_prefecture: this.props.sous_prefecture,
            quartier: this.props.quartier || '',
            superficie: this.props.superficie || 0,
            selcted_reg_code: this.props.code_reg || '',
            selcted_pref_code: this.props.code_pref || '',
            regions: getRegionsAndCodes(),
            prefectures: this.props.code_reg !== '' ? getPrefecturesAndCodesByRegionCode(this.props.code_reg) : [],
            s_prefectures: this.props.code_pref !== '' ? getSousprefecturesByPrefectureCode(this.props.code_pref) : []
        };

        this.nomChange = this.nomChange.bind(this);
        this.selctRegionActuelChange = this.selctRegionActuelChange.bind(this);
        this.selctPrefectureActuelChange = this.selctPrefectureActuelChange.bind(this);
        this.selctSPrefectureActuelChange = this.selctSPrefectureActuelChange.bind(this);
        this.quartierChange = this.quartierChange.bind(this);
        this.superficieChange = this.superficieChange.bind(this);
    }

    superficieChange(event) {
        this.setState({ superficie: event.target.value });
    }

    nomChange(event) {
        this.setState({ nom: event.target.value });
    }

    quartierChange(event) {
        this.setState({ quartier: event.target.value });
    }

    selctRegionActuelChange(event) {
        this.setState({ region: event.target.value });
        const code = event.target.options[event.target.selectedIndex].dataset.code
        this.setState({
            prefectures: getPrefecturesAndCodesByRegionCode(code),
            selcted_reg_code: code,
            s_prefectures: [],
            selcted_pref_code: '',
            prefecture: '',
            sous_prefecture: '',
            quartier: '',
        })
    }

    selctPrefectureActuelChange(event) {
        this.setState({ prefecture: event.target.value });
        const pcode = event.target.options[event.target.selectedIndex].dataset.pcode
        this.setState({
            s_prefectures: getSousprefecturesByPrefectureCode(pcode),
            selcted_pref_code: pcode,
            sous_prefecture: '',
            quartier: '',
        })
    }

    selctSPrefectureActuelChange(event) {
        this.setState({ sous_prefecture: event.target.value });
    }

    render() {
        const { nom, region, prefecture, sous_prefecture, quartier, superficie, regions, prefectures, s_prefectures, selcted_reg_code, selcted_pref_code } = this.state
        return (
            <React.Fragment >
                <div className = "row" >
                    <div className = "col-sm-6" >
                        <div className = "form-group" >
                            <label htmlFor = "prenom" > Nom du projet * </label> 
                            <input id = "prenom" type = 'text' value = { nom } className = 'form-control' name = "projet[nom]" required={true} onChange = { this.nomChange }/> 
                        </div> 
                    </div> 
                    <div className = "col-sm-6">
                        <div className = "form-group" >
                            <label htmlFor = "region" > Régions * </label>
                            <input type = 'hidden' name = "projet[code_reg]" value = { selcted_reg_code }/>
                            <select id="region" className="form-control" name="projet[region]" defaultValue={ region } required={true} onChange={ this.selctRegionActuelChange }>
                                <option value ="" > Sélectionner une region </option> 
                                {
                                    regions.map(r => ( 
                                        <option key={`region_${r.code}`} value = { r.region } data-code = { r.code } > { r.region } </option>
                                    ))
                                }
                            </select> 
                        </div>
                    </div>
                </div>
                <div className = "row" >
                    <div className = "col-sm-6" >
                        <div className = "form-group" >
                            <label htmlFor = "prefecture" > Préfecture * </label>
                            <input type = 'hidden' name = "projet[code_pref]" value = { selcted_pref_code }/> 
                            <select id = "prefecture" className = "form-control" name = "projet[prefecture]" defaultValue = { prefecture } required={true} onChange = { this.selctPrefectureActuelChange } >
                                <option value = "" > Sélectionner une préfecture </option> 
                                {
                                    prefectures.map(p => ( 
                                        < option key = { `prefect_${p.prefectureCode}`} value = { p.prefecture } data-pcode={ p.prefectureCode }> { p.prefecture } </option>
                                    ))
                                } 
                            </select>
                        </div>
                    </div>
                    <div className = "col-sm-6" >
                        <div className = "form-group" >
                            { prefecture === 'Conakry' && <label htmlFor = "sous-prefecture" > Commune * </label> }
                            { prefecture !== 'Conakry' && <label htmlFor = "sous-prefecture" > Sous - Préfecture * </label> }
                            <select id = "sous-prefecture" className = "form-control" name = "projet[sous_prefecture]" defaultValue = { sous_prefecture } required={true} onChange={this.selctSPrefectureActuelChange} >
                            <option value = "" > Sélectionner une commune ou sous préfecture </option>
                                {
                                    s_prefectures.map(sp => ( <option key = { `sprefect_${sp}` }
                                        value = { capitalize(sp) } > { capitalize(sp) } </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className = "row" >
                    <div className = "col-sm-6" >
                        <div className = "form-group" >
                            <label htmlFor = "quartier" > Quartier * </label>
                            <input id = "quartier" type = 'text' value = { quartier } className = 'form-control' name = "projet[quartier]" required={true} onChange={this.quartierChange}/>
                        </div>
                    </div>
                    <div className = "col-sm-6" >
                        <div className = "form-group" >
                            <label htmlFor = "superficie"> Superficie(en hectare) * </label>
                            <input id = "superficie" type = 'number' value = { superficie } className = 'form-control' name = "projet[superficie]" required={true} onChange={this.superficieChange}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Construction.propTypes = {
    nom: PropTypes.string,
    region: PropTypes.string,
    prefecture: PropTypes.string,
    sous_prefecture: PropTypes.string,
    quartier: PropTypes.string,
    superficie: PropTypes.number,
    code_reg: PropTypes.string,
    code_pref: PropTypes.string
};
export default Construction