import React from "react"


class Register extends React.Component {

    constructor(props) {
        super(props)

        this.textMatValidation = this.textMatValidation.bind(this)
        this.textNumValidation = this.textNumValidation.bind(this)
    }

    state = {
        secteur_user: '',
        validation_mat_class: '',
        validation_num_class: ''
    }

    textMatValidation(e) {
        const regex = /^[1-9][0-9]{5}[A-Za-z]$|^[1-9][0-9]{4}[/][Gg]$/;

        if (regex.test(e.target.value)) {
            this.setState({validation_mat_class: 'is-valid'});
            e.target.setCustomValidity('')
        } else {
            this.setState({validation_mat_class: 'is-invalid'});
            e.target.setCustomValidity('Invalid!')
        }
    }

    textNumValidation(e) {
        const regex = /^[A-Za-z][0-9]{7}$|^[A-Za-z][0-9]{15}$/;

        if (regex.test(e.target.value)) {
            this.setState({validation_num_class: 'is-valid'});
            e.target.setCustomValidity('')
        } else {
            this.setState({validation_num_class: 'is-invalid'});
            e.target.setCustomValidity('Invalid!')
        }
    }

    render() {
        const {validation_mat_class, validation_num_class, secteur_user} = this.state
        return (
            <>
                <div className="row form-group customRadio">
                    <div className="col-md-12">
                        <input type="radio" value="fonctionnaire_user" name="secteur_user" checked={secteur_user === 'fonctionnaire_user'} onChange={() => this.setState({secteur_user: 'fonctionnaire_user', validation_mat_class: '', validation_num_class: ''})} id="user_type_user_fonctionnaire_user" />
                        <label className="mr-2" htmlFor="user_type_user_fonctionnaire_user">Je suis fonctionaire</label>
                    </div>
                    <div className="col-md-12">
                        <input type="radio" value="secteur_prive" name="secteur_user" checked={secteur_user === 'secteur_prive'} onChange={() => this.setState({secteur_user: 'secteur_prive', validation_mat_class: '', validation_num_class: ''})} id="user_type_user_secteur_prive" />
                        <label className="mr-2" htmlFor="user_type_user_secteur_prive">Je suis du secteur privé</label>
                    </div>
                    {/* <div className="col-md-12">
                        <input type="radio" value="secteur_diaspora" name="secteur_user" checked={secteur_user === 'secteur_diaspora'} onChange={() => this.setState({secteur_user: 'secteur_diaspora', validation_mat_class: '', validation_num_class: ''})} id="user_type_user_secteur_diaspora" />
                        <label className="mr-2" htmlFor="user_type_user_secteur_diaspora">Je suis de la diaspora</label>
                    </div> */}
                    <div className="col-md-12">
                        <input type="radio" value="secteur_informel" name="secteur_user" checked={secteur_user === 'secteur_informel'} onChange={() => this.setState({secteur_user: 'secteur_informel', validation_mat_class: '', validation_num_class: ''})} id="user_type_user_secteur_informel" />
                        <label className="mr-2" htmlFor="user_type_user_secteur_informel">Je suis du secteur informel</label>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-7">
                        <input autoComplete="prenoms" className="form-control" placeholder="Votre prénom" type="text" name="user[prenoms]" id="user_prenoms" required={true} />
                        <div className="invalid-feedback">Prénom est obligatoire.</div>
                    </div>
                    <div className="form-group col-md-5">
                        <input autoComplete="nom" className="form-control" placeholder="Votre nom" type="text" name="user[nom]" id="user_nom" required={true} />
                        <div className="invalid-feedback">Nom est obligatoire.</div>
                    </div>
                </div>
                {
                    secteur_user === '' && 
                    <div className="row">
                        <div className="form-group col-md-12">
                            <input className={`form-control ${validation_mat_class}`} placeholder="Votre matricule" onBlur={this.textMatValidation} maxLength="7" size="7" type="text" name="user[matricule]" id="user_matricule" required={true} />
                            <div className="invalid-feedback">Votre matricule est obligatoire et doit avoir le format guinéen .</div>
                        </div>
                    </div>
                }
                {
                    secteur_user === 'fonctionnaire_user' && 
                    <div className="row">
                        <div className="form-group col-md-12">
                            <input className={`form-control ${validation_mat_class}`} placeholder="Votre matricule" onBlur={this.textMatValidation} maxLength="7" size="7" type="text" name="user[matricule]" id="user_matricule" required={true} />
                            <div className="invalid-feedback">Votre matricule est obligatoire et doit avoir le format guinéen .</div>
                        </div>
                    </div>
                }
                {
                    secteur_user !== 'fonctionnaire_user' && secteur_user !== '' && 
                    <div className="row">
                        <div className="form-group col-md-12">
                            <input className={`form-control ${validation_num_class}`} placeholder="Numéro de votre carte d'identité ou passport" type="text" name="user[matricule]" id="user_matricule" onBlur={this.textNumValidation} required={true} />
                            <div className="invalid-feedback">Le numéro de votre carte d'identité ou passport est obligatoire et doit avoir le format guinéen.</div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default Register
