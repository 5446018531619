import React from "react"
import PropTypes from "prop-types"
import { getRegionsAndCodes, getPrefecturesAndCodesByRegionCode, getSousprefecturesByPrefectureCode } from 'guinea-subdivision';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

class Ville extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      region_choix_1: this.props.regionChoix1,
      prefecture_choix_1: this.props.prefectureChoix1,
      sous_prefecture_choix_1: this.props.sousPrefectureChoix1,
      code_reg_ch1: this.props.codeRegCh1 || '',
      code_pref_ch1: this.props.codePrefCh1 || '',
      prefectures_1: this.props.codeRegCh1 !== ''? getPrefecturesAndCodesByRegionCode(this.props.codeRegCh1) : [],
      s_prefectures_1: this.props.codePrefCh1 !== ''? getSousprefecturesByPrefectureCode(this.props.codePrefCh1) : [],

      region_choix_2: this.props.regionChoix2,
      prefecture_choix_2: this.props.prefectureChoix2,
      sous_prefecture_choix_2: this.props.sousPrefectureChoix2,
      code_reg_ch2: this.props.codeRegCh2 || '',
      code_pref_ch2: this.props.codePrefCh2 || '',
      prefectures_2: this.props.codeRegCh2 !== ''? getPrefecturesAndCodesByRegionCode(this.props.codeRegCh2) : [],
      s_prefectures_2: this.props.codePrefCh2 !== ''? getSousprefecturesByPrefectureCode(this.props.codePrefCh2) : [],

      region_choix_3: this.props.regionChoix3,
      prefecture_choix_3: this.props.prefectureChoix3,
      sous_prefecture_choix_3: this.props.sousPrefectureChoix3,
      code_reg_ch3: this.props.codeRegCh3 || '',
      code_pref_ch3: this.props.codePrefCh3 || '',
      prefectures_3: this.props.codeRegCh3 !== ''? getPrefecturesAndCodesByRegionCode(this.props.codeRegCh3) : [],
      s_prefectures_3: this.props.codePrefCh3 !== ''? getSousprefecturesByPrefectureCode(this.props.codePrefCh3) : [],

      regions: getRegionsAndCodes() || [],
    };

    this.selctRegion1Change = this.selctRegion1Change.bind(this);
    this.selctPrefecture1Change = this.selctPrefecture1Change.bind(this);
    this.selctSPrefecture1Change = this.selctSPrefecture1Change.bind(this);

    this.selctRegion2Change = this.selctRegion2Change.bind(this);
    this.selctPrefecture2Change = this.selctPrefecture2Change.bind(this);
    this.selctSPrefecture2Change = this.selctSPrefecture2Change.bind(this);

    this.selctRegion3Change = this.selctRegion3Change.bind(this);
    this.selctPrefecture3Change = this.selctPrefecture3Change.bind(this);
    this.selctSPrefecture3Change = this.selctSPrefecture3Change.bind(this);
  }


  selctRegion1Change(event) {
    this.setState({region_choix_1: event.target.value});
    const code1 = event.target.options[event.target.selectedIndex].dataset.code1
    this.setState({
      prefectures_1: getPrefecturesAndCodesByRegionCode(code1),
      code_reg_ch1: code1,
      s_prefectures_1: [],
      code_pref_ch1: '',
      prefecture_choix_1: '',
      sous_prefecture_choix_1: '',
    })
  }

  selctPrefecture1Change(event) {
    this.setState({prefecture_choix_1: event.target.value});
    const pcode1 = event.target.options[event.target.selectedIndex].dataset.pcode1
    this.setState({
      s_prefectures_1: getSousprefecturesByPrefectureCode(pcode1),
      code_pref_ch1: pcode1,
      sous_prefecture_choix_1: '',
    })
  }

  selctSPrefecture1Change(event) {
    this.setState({sous_prefecture_choix_1: event.target.value});
  }


  selctRegion2Change(event) {
    this.setState({region_choix_2: event.target.value});
    const code2 = event.target.options[event.target.selectedIndex].dataset.code2
    this.setState({
      prefectures_2: getPrefecturesAndCodesByRegionCode(code2),
      code_reg_ch2: code2,
      s_prefectures_2: [],
      code_pref_ch2: '',
      prefecture_choix_2: '',
      sous_prefecture_choix_2: '',
    })
  }

  selctPrefecture2Change(event) {
    this.setState({prefecture_choix_2: event.target.value});
    const pcode2 = event.target.options[event.target.selectedIndex].dataset.pcode2
    this.setState({
      s_prefectures_2: getSousprefecturesByPrefectureCode(pcode2),
      code_pref_ch2: pcode2,
      sous_prefecture_choix_2: '',
    })
  }

  selctSPrefecture2Change(event) {
    this.setState({sous_prefecture_choix_2: event.target.value});
  }



  selctRegion3Change(event) {
    this.setState({region_choix_3: event.target.value});
    const code3 = event.target.options[event.target.selectedIndex].dataset.code3
    this.setState({
      prefectures_3: getPrefecturesAndCodesByRegionCode(code3),
      code_reg_ch3: code3,
      s_prefectures_3: [],
      code_pref_ch3: '',
      prefecture_choix_3: '',
      sous_prefecture_choix_3: '',
    })
  }

  selctPrefecture3Change(event) {
    this.setState({prefecture_choix_3: event.target.value});
    const pcode3 = event.target.options[event.target.selectedIndex].dataset.pcode3
    this.setState({
      s_prefectures_3: getSousprefecturesByPrefectureCode(pcode3),
      code_pref_ch3: pcode3,
      sous_prefecture_choix_3: '',
    })
  }

  selctSPrefecture3Change(event) {
    this.setState({sous_prefecture_choix_3: event.target.value});
  }


  render () {
    const {region_choix_1, prefecture_choix_1, sous_prefecture_choix_1, code_reg_ch1, code_pref_ch1, prefectures_1, s_prefectures_1, region_choix_2, prefecture_choix_2, sous_prefecture_choix_2, code_reg_ch2, code_pref_ch2, prefectures_2, s_prefectures_2, region_choix_3, prefecture_choix_3, sous_prefecture_choix_3, code_reg_ch3, code_pref_ch3, prefectures_3, s_prefectures_3, regions} = this.state

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="region_choix_1">Région (Choix 1) </label>
              <input type='hidden' name="code_reg_ch1" value={code_reg_ch1}/>
              <select className="form-control" name="demande_logement[region_choix_1]" defaultValue={region_choix_1} onChange={this.selctRegion1Change}>
                <option value="" >Sélectionner une region</option>
                {
                  regions.map(r => (
                    <option key={`region1_${r.code}`} value={r.region} data-code1={r.code}>{r.region}</option>
                  ))
                }
              </select>
              <div className="invalid-feedback">Le prémier choix de la région est obligatoire.</div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="region_choix_2">Région (Choix 2) </label>
              <input type='hidden' name="code_reg_ch2" value={code_reg_ch2}/>
              <select className="form-control" name="demande_logement[region_choix_2]" defaultValue={region_choix_2} onChange={this.selctRegion2Change}>
                <option value="" >Sélectionner une region</option>
                {
                  regions.map(r => (
                    <option key={`region2_${r.code}`} value={r.region} data-code2={r.code}>{r.region}</option>
                  ))
                }
              </select>
            </div>
          </div>
          
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="region_choix_3">Région (Choix 3) </label>
              <input type='hidden' name="code_reg_ch3" value={code_reg_ch3}/>
              <select className="form-control" name="demande_logement[region_choix_3]" defaultValue={region_choix_3} onChange={this.selctRegion3Change}>
                <option value="" >Sélectionner une region</option>
                {
                  regions.map(r => (
                    <option key={`region3_${r.code}`} value={r.region} data-code3={r.code}>{r.region}</option>
                  ))
                }
              </select>
            </div>
          </div>
          
        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="prefecture_choix_1">Préfecture (Choix 1) </label>
              <input type='hidden' name="code_pref_ch1" value={code_pref_ch1}/>
              <select className="form-control" name="demande_logement[prefecture_choix_1]" defaultValue={prefecture_choix_1} onChange={this.selctPrefecture1Change}>
                <option value="" >Sélectionner une préfecture</option>
                {
                  prefectures_1.map(p => (
                    <option key={`prefect1_${p.prefectureCode}`} value={p.prefecture} data-pcode1={p.prefectureCode}>{p.prefecture}</option>
                  ))
                }
              </select>
              <div className="invalid-feedback">Le prémier choix de la préfecture est obligatoire.</div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="prefecture_choix_2">Préfecture (Choix 2) </label>
              <input type='hidden' name="code_pref_ch2" value={code_pref_ch2}/>
              <select className="form-control" name="demande_logement[prefecture_choix_2]" defaultValue={prefecture_choix_2} onChange={this.selctPrefecture2Change}>
                <option value="" >Sélectionner une préfecture</option>
                {
                  prefectures_2.map(p => (
                    <option key={`prefect2_${p.prefectureCode}`} value={p.prefecture} data-pcode2={p.prefectureCode}>{p.prefecture}</option>
                  ))
                }
              </select>
            </div>
          </div>
          
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="prefecture_choix_3">Préfecture (Choix 3) </label>
              <input type='hidden' name="code_pref_ch3" value={code_pref_ch3}/>
              <select className="form-control" name="demande_logement[prefecture_choix_3]" defaultValue={prefecture_choix_3} onChange={this.selctPrefecture3Change}>
                <option value="" >Sélectionner une préfecture</option>
                {
                  prefectures_3.map(p => (
                    <option key={`prefect3_${p.prefectureCode}`} value={p.prefecture} data-pcode3={p.prefectureCode}>{p.prefecture}</option>
                  ))
                }
              </select>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              {
                prefecture_choix_1 === 'Conakry' &&
                <label htmlFor="sous_prefecture_choix_1">Commune (Choix 1) </label>
              }
              {
                prefecture_choix_1 !== 'Conakry' &&
                <label htmlFor="sous_prefecture_choix_1">Sous-Préfecture (Choix 1) </label>
              }
              <select className="form-control" name="demande_logement[sous_prefecture_choix_1]" defaultValue={sous_prefecture_choix_1} onChange={this.selctSPrefecture1Change}>
                <option value="" >Sélectionner une sous préfecture</option>
                {
                  s_prefectures_1.map(sp => (
                    <option key={`sprefect1_${sp}`} value={capitalize(sp)}>{capitalize(sp)}</option>
                  ))
                }
              </select>
              <div className="invalid-feedback">Le prémier choix de la sous préfecture est obligatoire.</div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              {
                prefecture_choix_2 === 'Conakry' &&
                <label htmlFor="sous_prefecture_choix_2">Commune (Choix 2) * </label>
              }
              {
                prefecture_choix_2 !== 'Conakry' &&
                <label htmlFor="sous_prefecture_choix_2">Sous-Préfecture (Choix 2) * </label>
              }
              <select className="form-control" name="demande_logement[sous_prefecture_choix_2]" defaultValue={sous_prefecture_choix_2} onChange={this.selctSPrefecture2Change}>
                <option value="" >Sélectionner une sous préfecture</option>
                {
                  s_prefectures_2.map(sp => (
                    <option key={`sprefect2_${sp}`} value={capitalize(sp)}>{capitalize(sp)}</option>
                  ))
                }
              </select>
            </div>
          </div>
          
          <div className="col-sm-4">
            <div className="form-group">
              {
                prefecture_choix_3 === 'Conakry' &&
                <label htmlFor="sous_prefecture_choix_3">Commune (Choix 3) * </label>
              }
              {
                prefecture_choix_3 !== 'Conakry' &&
                <label htmlFor="sous_prefecture_choix_3">Sous-Préfecture (Choix 3) * </label>
              }
              <select className="form-control" name="demande_logement[sous_prefecture_choix_3]" defaultValue={sous_prefecture_choix_3} onChange={this.selctSPrefecture3Change}>
                <option value="" >Sélectionner une sous préfecture</option>
                {
                  s_prefectures_3.map(sp => (
                    <option key={`sprefect2_${sp}`} value={capitalize(sp)}>{capitalize(sp)}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Ville.propTypes = {
  regionChoix1: PropTypes.string,
  prefectureChoix1: PropTypes.string,
  sousPrefectureChoix1: PropTypes.string,
  codeRegCh1: PropTypes.string,
  codePrefCh1: PropTypes.string,

  regionChoix2: PropTypes.string,
  prefectureChoix2: PropTypes.string,
  sousPrefectureChoix2: PropTypes.string,
  codeRegCh2: PropTypes.string,
  codePrefCh2: PropTypes.string,

  regionChoix3: PropTypes.string,
  prefectureChoix3: PropTypes.string,
  sousPrefectureChoix3: PropTypes.string,
  codeRegCh3: PropTypes.string,
  codePrefCh3: PropTypes.string
};
export default Ville
