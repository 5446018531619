import React from "react"
import PropTypes from "prop-types"
import $ from 'jquery'


class Assignation extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            destinataire: "",
        }

    }

    handleDestinataireChange = (e) => {
        const valeur = e.target.value
        this.setState({destinataire: valeur})
        
        if (valeur !== '') {
            const assignations = this.props.assignations.filter(ass => ass.recipient_id === valeur);

            if(assignations.length){
                $('#dest_info_text').append('<div class="alert-info alert-dismissible" >Cette demande est deja assigne , <br /> si vous insistez la precedante assignation sera annuler <br />en laissant place a la nouvelle.</div>');
            }
        }else{
            $('#dest_info_text').html('')
        }
    }

    render() {
        return (
            <div>
                <label htmlFor="destinataire" className="text-capitalize">Destinataires * </label>
                <select className="custom-select" id="destinataire" name="destinataire" defaultValue={this.state.destinataire} onChange={this.handleDestinataireChange} required={true}>
                    <option value="">--- Sélectionner un destinataire ---</option>
                    {
                        this.props.destinataires.map(d=>(
                            <option key={d.id} value={d.id}> {d.prenoms} {d.nom}</option>
                        ))
                    }
                </select>
                <div className="invalid-feedback" id='dest_help_text'>Le destinataire est obligatoire.</div>
                <div id='dest_info_text'></div>
            </div>
        )
    }
}

Assignation.propTypes = {
    assignations: PropTypes.array, 
    destinataires: PropTypes.array
}


export default Assignation