import React from "react"
import PropTypes from "prop-types"
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css'

class CreditConjointe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      montant_emprunte: this.props.montantEmprunte || 0,
      mensualite: this.props.mensualite || 0,
      banque_emprunt: this.props.banqueEmprunt || '',
      date_debut_emprunt: this.props.dateDebutEmprunt === null || this.props.dateDebutEmprunt === ''? '': new Date(`${this.props.dateDebutEmprunt.replace(/ UTC/, '')}`),
      date_fin_emprunt: this.props.dateFinEmprunt === null || this.props.dateFinEmprunt === ''? '': new Date(`${this.props.dateFinEmprunt.replace(/ UTC/, '')}`),
      capital_restant_due: this.props.capitalRestantDue || 0
    };

  }

  render () {
    const {montant_emprunte, mensualite, banque_emprunt, date_debut_emprunt, date_fin_emprunt, capital_restant_due} = this.state
    const dates = new Date()
    const days_of_month = dates.getDate()
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email">Montant du Prêt (GNF) * </label>
              <input type="number" name="demande_logement[montant_emprunte_conjointe]" min="0" max="2000000000" value={montant_emprunte} className="form-control" required={true} onChange={event => {this.setState({montant_emprunte: event.target.value.replace(/\D/,'')})}} />
              <div className="invalid-feedback">Le montant du prêt est obligatoire et doit être compris entre [0 - 2.000.000.000].</div>
            </div>
          </div>
          {
            montant_emprunte !== 0 && montant_emprunte !== '0' && montant_emprunte !== '' &&
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Mensualité (GNF) * </label>
                <input type="number" min='1' max="1000000000" name="demande_logement[mensualite_conjointe]" value={mensualite} className="form-control" required={true} onChange={event => {this.setState({mensualite: event.target.value.replace(/\D/,'')})}} />
                <div className="invalid-feedback">La mensualité est obligatoire et doit être comprise entre [0 - 1.000.000.000].</div>
              </div>
            </div>
          }
        </div>
        
        {
          montant_emprunte !== 0 && montant_emprunte !== '0' && montant_emprunte !== '' &&
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Banque * </label>
                <select required={true} className="form-control" defaultValue={banque_emprunt} name="demande_logement[banque_emprunt_conjointe]" onChange={event => {this.setState({banque_emprunt: event.target.value})}}>
                  <option value="">--- Sélectionner une banque ---</option>
                  {
                    this.props.banques.map((li, k) => (
                      <option key={`banque_sal_${k}`} value={li}>{li}</option>
                    ))
                  }
                </select>
                <div className="invalid-feedback">Le nom de la banque est obligatoire.</div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Crédit restant dû * </label>
                <input type="number" min="0" max="2000000000" name="demande_logement[capital_restant_due_conjointe]" value={capital_restant_due} className="form-control" required={true} onChange={event => {this.setState({capital_restant_due: event.target.value.replace(/\D/,'')})}} />
                <div className="invalid-feedback">Le crédit restant dû est obligatoire et doit être compris entre [0 - 2.000.000.000].</div>
              </div>
            </div>
          </div>
        }

        {
          montant_emprunte !== 0 && montant_emprunte !== '0' && montant_emprunte !== '' &&
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Date de début * </label>
                <Flatpickr
                    options={{
                      maxDate: dates.fp_incr(-0),
                      altInput: true,
                      altFormat: "F Y",
                      dateFormat: "Y-m-d",
                      disableMobile: true,
                      plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "Y-m-d", altFormat: "F Y"})],
                    }}
                    className='form-control read-input'
                    value={date_debut_emprunt}
                    onChange={date_debut_emprunt => { this.setState({date_debut_emprunt}) }}
                    required={true}
                    name="demande_logement[date_debut_emprunt_conjointe]"
                    id="demande_logement_date_debut_emprunt"
                    placeholder="Selectionner une date" />
                    <div className="invalid-feedback">La date de début du crédit est obligatoire.</div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Date de fin * </label>
                <Flatpickr
                    options={{
                      minDate: dates.fp_incr(-days_of_month),
                      altInput: true,
                      altFormat: "F Y",
                      dateFormat: "Y-m-d",
                      disableMobile: true,
                      plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "Y-m-d", altFormat: "F Y"})],
                    }}
                    className='form-control read-input'
                    value={date_fin_emprunt}
                    onChange={date_fin_emprunt => { this.setState({date_fin_emprunt}) }}
                    required={true}
                    name="demande_logement[date_fin_emprunt_conjointe]"
                    id="demande_logement_date_fin_emprunt"
                    placeholder="Selectionner une date" />
                    <div className="invalid-feedback">La date de fin du crédit est obligatoire.</div>
              </div>
            </div>
          </div>
        }

      </React.Fragment>
    );
  }
}

CreditConjointe.propTypes = {
  montantEmprunte: PropTypes.string,
  mensualite: PropTypes.number,
  banqueEmprunt: PropTypes.string,
  dateDebutEmprunt: PropTypes.node,
  dateFinEmprunt: PropTypes.node,
  capitalRestantDue: PropTypes.string
};
export default CreditConjointe
