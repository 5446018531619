import React from "react"
import PropTypes from "prop-types"
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'


const lienData = [
  "Arrière-cousin", "Arrière-grand-mère", "Arrière-grand-oncle", "Arrière-grand-père", "Arrière-grand-tante", "Arrière-neveu, arrière-nièce",
  "Arrière-petit-fils, arrière-petite-fille","Arrière-petit-fils, arrière-petite-fille","Arrière-petit-neveu, arrière-petite-nièce","Arrière-petits-enfants",
  "Beau-fils","Beau-frère","Beau-père","Belle-fille","Belle-mère","Belle-soeur","Conjoint(e)","Cousin(e)","Demi-frère","Demi-soeur","Enfant adoptif","Enfant",
  "Epoux.se", "Fille","Fils","Frère","Gendre","Grand-mère","Grand-oncle","Grand-père","Grands-parents","Grand-tante","Marie", "Marraine","Mère","Neveu","Nièce","Oncle",
  "Parent éloigné","Parent proche","Parrain","Père","Petit-cousin","Petite-fille","Petit-fils","Petit-neveu, petite-nièce","Petits-enfants","Soeur","Tante",
  "Neveu","Nièce"
]

const ocupation = [ 
  "Non scolarisé",
  "Élève",
  "Étudiant",
  "Salarié",
  "Sans emploi",
  "Entrepreneur",
]

class Famille extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      situation_maritale: this.props.situationMaritale || '',
      nombre_pers_a_charge: this.props.nombrePersACharge || 0,
      aquerir_avec_conjoint: this.props.aquerir_avec_conjoint? 'Oui': 'Non',
      personnes: this.props.personnes
    };

    this.nbPersAchargeChange = this.nbPersAchargeChange.bind(this);
    this.selectSituationFamilialeChange = this.selectSituationFamilialeChange.bind(this);
    this.personneNomChange = this.personneNomChange.bind(this);
    this.personnePrenomChange = this.personnePrenomChange.bind(this);
    this.personneGenreChange = this.personneGenreChange.bind(this);
    this.personneLienChange = this.personneLienChange.bind(this);
    this.personneDateNaissChange = this.personneDateNaissChange.bind(this)
    this.personneOccupationChange = this.personneOccupationChange.bind(this)
  }

  updatePersonnes(value) {
    let elemNb = value - this.state.personnes.length
    elemNb = this.state.personnes.length + elemNb

    let arrElem = []

    if(elemNb >= this.props.personnes.length) {
      let newElemNb = elemNb - this.props.personnes.length
      arrElem = [...this.props.personnes]
      for (let i = 0; i < newElemNb; i++) {
        arrElem.push({
          nom: '',
          prenoms: '',
          genre: '',
          date_naiss: '',
          lien_parente: '',
          occupation: '',
        })
      }
    } else {
      arrElem = this.props.personnes.slice(0, elemNb)
    }
    this.setState({personnes: arrElem})
  }

  selectSituationFamilialeChange(event) {
    this.setState({situation_maritale: event.target.value});
  }

  nbPersAchargeChange(event) {
    const val = event.target.value.replace(/\D/,'')
    this.setState({nombre_pers_a_charge: val})
    this.updatePersonnes(parseInt(val) || 0)
  }

  personneNomChange(event, ind) {
    this.setState({personnes: [
      ...this.state.personnes.slice(0,ind),
      { 
        ...this.state.personnes[ind],
        nom: event.target.value
      },
      ...this.state.personnes.slice(ind+1)
    ]})
  }

  personnePrenomChange(event, ind) {
    this.setState({personnes: [
      ...this.state.personnes.slice(0,ind),
      { 
        ...this.state.personnes[ind],
        prenoms: event.target.value
      },
      ...this.state.personnes.slice(ind+1)
    ]})
  }

  personneGenreChange(event, ind) {
    this.setState({personnes: [
      ...this.state.personnes.slice(0,ind),
      { 
        ...this.state.personnes[ind],
        genre: event.target.value
      },
      ...this.state.personnes.slice(ind+1)
    ]})
  }

  personneLienChange(event, ind) {
    this.setState({personnes: [
      ...this.state.personnes.slice(0,ind),
      { 
        ...this.state.personnes[ind],
        lien_parente: event.target.value
      },
      ...this.state.personnes.slice(ind+1)
    ]})
  }

  personneDateNaissChange(val, ind) {
    this.setState({personnes: [
      ...this.state.personnes.slice(0,ind),
      { 
        ...this.state.personnes[ind],
        date_naiss: val[0]
      },
      ...this.state.personnes.slice(ind+1)
    ]})
  }

  personneOccupationChange(event, ind) {
    this.setState({personnes: [
      ...this.state.personnes.slice(0,ind),
      { 
        ...this.state.personnes[ind],
        occupation: event.target.value
      },
      ...this.state.personnes.slice(ind+1)
    ]})
  }

  render () {
    const {situation_maritale, nombre_pers_a_charge, aquerir_avec_conjoint, personnes} = this.state
    
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="prenom">Situation Matrimoniale * </label>
              <select className="form-control" defaultValue={situation_maritale} name="situation_maritale" required={true} onChange={this.selectSituationFamilialeChange}>
                <option value="" >Sélectionner une situation</option>
                <option value="Célibataire">Célibataire</option>
                <option value="Marié.e">Marié.e</option>
                <option value="Divorcé.e">Divorcé.e</option>
                <option value="Veuf.ve">Veuf.ve</option>
              </select>
              <div className="invalid-feedback">La situation matrimoniale est obligatoire.</div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email">Nombre de personne en charge * </label>
              <input type='number' value={nombre_pers_a_charge} className='form-control' name='nombre_pers_a_charge' required={true} min='0' max="15" onChange={this.nbPersAchargeChange}/>
              <div className="invalid-feedback">Le nombre de personne en charge est obligatoire et doit etre de 15 au maximum.</div>
            </div>
          </div>
        </div>

        {
          situation_maritale === 'Marié.e' &&
          <div className="row pl-3">
            <div className="col-sm-6">
              <div className="row">
                <label htmlFor="email">Voulez-vous acquérir le logement avec votre conjoint.e?</label>
                <div className="col-sm-12">
                  <div className="form-check form-check-inline">
                    <input id="oui" type="radio" name="aquerir_avec_conjoint" value="Oui" onChange={event => this.setState({aquerir_avec_conjoint: event.target.value})} defaultChecked={aquerir_avec_conjoint==="Oui"} className="form-check-input" required={true} />
                    <label htmlFor="oui" className="form-check-label">Oui </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input id="non" type="radio" name="aquerir_avec_conjoint" value="Non" onChange={event => this.setState({aquerir_avec_conjoint: event.target.value})} defaultChecked={aquerir_avec_conjoint==="Non"} className="form-check-input" required={true} />
                    <label htmlFor="non" className="form-check-label">Non </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {
          personnes.map((ind, i) =>(
            <div key={`persone_${i}`}>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="genre">Genre *</label>
                        <select required={true} className="form-control" defaultValue={ind.genre} name={`personne_charge_genre_${i}`} onChange={event => this.personneGenreChange(event, i)}>
                          <option value="Masculin">Masculin</option>
                          <option value="Féminin">Féminin</option>
                        </select>
                        <div className="invalid-feedback">Le genre est obligatoire.</div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="nom">Nom *</label>
                        <input type='text' defaultValue={ind.nom} className='form-control' name={`personne_charge_nom_${i}`} required={true} onChange={event => this.personneNomChange(event, i)}/>
                        <div className="invalid-feedback">Le nom est obligatoire.</div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="prenom">Prénom *</label>
                        <input type='text' defaultValue={ind.prenoms} className='form-control' name={`personne_charge_prenoms_${i}`} required={true} onChange={event => this.personnePrenomChange(event, i)}/>
                        <div className="invalid-feedback">Le prénom est obligatoire.</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor={`personne_charge_date_naiss_${i}`}>Date de Naissance *</label>
                        <Flatpickr
                          options={{
                            maxDate: new Date().fp_incr(-0),
                            altInput: true,
                            altFormat: "j F Y",
                            dateFormat: "Y-m-d",
                            disableMobile: true,
                          }}
                          className='form-control read-input'
                          value={ind.date_naiss}
                          onChange={val => { this.personneDateNaissChange(val, i) }}
                          required={true}
                          name={`personne_charge_date_naiss_${i}`}
                          id={`personne_charge_date_naiss_${i}`}
                          placeholder="Selectionner une date" />
                        <div className="invalid-feedback">La date de naissance est obligatoire.</div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="prenom">Lien de parente *</label>
                        <select required={true} className="form-control" defaultValue={ind.lien_parente} name={`personne_charge_lien_parente_${i}`} onChange={event => this.personneLienChange(event, i)}>
                          {
                            lienData.map((li, k) => (
                              <option key={`lien_${i}${k}`} value={li}>{li}</option>
                            ))
                          }
                        </select>
                        <div className="invalid-feedback">Le lien de parenté est obligatoire.</div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="nom">Occupation *</label>
                        <select required={true} className="form-control" defaultValue={ind.occupation} name={`personne_charge_occupation_${i}`} onChange={event => this.personneOccupationChange(event, i)}>
                          {
                            ocupation.map((li, k) => (
                              <option key={`ocupation_${i}${k}`} value={li}>{li}</option>
                            ))
                          }
                        </select>
                        <div className="invalid-feedback">L'occupation est obligatoire.</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          ))
        }

      </React.Fragment>
    );
  }
}

Famille.propTypes = {
  situationMaritale: PropTypes.string,
  nombrePersACharge: PropTypes.number,
  personnes: PropTypes.array
};
export default Famille
