import React, { useState, useEffect } from 'react';
import { Markup } from 'interweave';

const Notifications = () => {
    const [notifis, setNotifs] = useState([])

    const handleSuccess = (data) => {
        setNotifs(data)
    }

    const handleNotification = (notif) => {
        fetch(notif.path)
        .then(()=> { window.location = notif.url })
        .catch(err => console.log('Error ', err))
    }

    useEffect(() => {
        fetch('/notifications.json')
        .then(data => data.json())
        .then(handleSuccess)
        .catch(err => console.log('Error ', err))
    }, [1])

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle lien_a notifs_link"  href="#" id="notification" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="far fa-bell" aria-hidden="true"></i>
            </a>
            <span className={`badge badge-primary counter counter-lg${notifis.length ? ' ball-pop' : ''}`}>{notifis.length > 0 ? notifis.length : ''}</span>
            <div className="dropdown-menu data-menu" aria-labelledby="notification" data-behavior="notification-items">
                {
                    notifis.slice(0, 4).map(notif => (
                            <div onClick={() => handleNotification(notif)} className="dropdown-item data-item break-word" key={notif.id} >
                                <span className="alert-content">
                                    <Markup content={`${notif.message} <em><mark>(${new Intl.DateTimeFormat('fr',{day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(notif.created_at))})</mark></em>`} />
                                </span>
                            </div>
                        )
                    )
                }
                <div className="dropdown-divider div-style"></div>
                <a href='/notifications' className='text-center dropdown-item toutes-style'> <span>Toutes les notifications</span> </a>
            </div>
        </li>
    )
}

export default Notifications;