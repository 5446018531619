import React, { useEffect, useState } from "react"
import { delaisDay, timeDiffCalc } from "../utils/functions";

function AssignationShow({assignation_start, assignation_end, assignation_comment, assignation_delete}){
    const [difference, setDifference] = useState(null);
    const [delais, setDelais] = useState(delaisDay(new Date(assignation_start), new Date(assignation_end)));
    const [delaisPasse, setDelaisPasse] = useState(delaisDay(new Date(), new Date(assignation_end)));
    const [resteJours, setResteJours] = useState(0);

    useEffect(() => {
        const dateDiff = timeDiffCalc(new Date(assignation_end), new Date())
        const timer = setInterval(() => {
            setDifference(dateDiff[0])
            setResteJours(dateDiff[1])
            setDelaisPasse(delaisDay(new Date(), new Date(assignation_end)))
        }, 1000);
        
        if (delaisPasse[0]) {
            clearInterval(timer)
        }

        return () => {
            clearInterval(timer)
        }    
    }, [assignation_end, difference, delaisPasse]);

    return (
        <div className="container">
            {
                assignation_delete &&
                <div style={{backgroundColor: '#007bff', color: '#fff', padding: '10px', textAlign: 'center'}}>
                    Cette assignation a ete supprimer
                </div>
            }
            {
                !assignation_delete && delaisPasse[0] &&
                <div style={{backgroundColor: '#dc3545', color: '#fff', padding: '10px', textAlign: 'center'}} datatoggle="tooltip" dataplacement="top" title={assignation_comment}>
                    Le delais de {delais[1]} jours qui vous a ete attribue est depassé de {delaisPasse[1]} jours.
                </div>
            }
            {
                !assignation_delete && difference && resteJours <= 3 && !delaisPasse[0] &&
                <div style={{backgroundColor: '#ffc107', color: '#000', padding: '10px', textAlign: 'center'}} datatoggle="tooltip" dataplacement="top" title={assignation_comment}>
                    Il vous reste {difference} pour le traitement de ce dossier
                </div>
            }
            {
                !assignation_delete && difference && resteJours > 3 && !delaisPasse[0] &&
                <div style={{backgroundColor: '#28a745', color: '#fff', padding: '10px', textAlign: 'center'}} datatoggle="tooltip" dataplacement="top" title={assignation_comment}>
                    Il vous reste {difference} pour le traitement de ce dossier
                </div>
            }
        </div>
    )
}

export default AssignationShow 