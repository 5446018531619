import React from "react"
import PropTypes from "prop-types"


const grades_list = {
    "Terre": {
        "Militaires du Rang": ["Soldat de 2eme Classe", "Caporal", "Caporal-Chef"],
        "Sous-officiers subalternes": ["Sergent", "Sergemt–Chef"],
        "Sous-officiers superieurs": ["Adjudant", "Adjudant-Chef", "Major"],
        "Officiers subalternes": ["Aspirant (Ecole)", "Sous-Lieutenant", "Lieutenant", "Capitaine"],
        "Officiers superieurs": ["Commandant ou Chef d’escadrons", "Lieutenant-colonel", "Colonel"]
    },
    "Air": {
        "Militaires du Rang": ["Soldat de 2eme Classe", "Caporal", "Caporal-Chef"],
        "Sous-officiers subalternes": ["Sergent", "Sergemt–Chef"],
        "Sous-officiers superieurs": ["Adjudant", "Adjudant-Chef", "Major"],
        "Officiers subalternes": ["Aspirant (Ecole)", "Sous-Lieutenant", "Lieutenant", "Capitaine"],
        "Officiers superieurs": ["Commandant ou Chef d’escadrons", "Lieutenant-colonel", "Colonel"],
    },
    "Mer": {
        "Militaires du Rang": ["Matelot", "Quartier", "Maitre de 2eme Classe", "Quartier Maitre de 1ere Classe"],
        "Sous-officiers subalternes": ["Second Maitre", "Maitre"],
        "Sous-officiers superieurs": ["Premier Maitre", "Maitre Principal", "Major"],
        "Officiers subalternes": ["Aspirant(Ecole)", "Enseigne de Vaisseau de 2eme Classe", "Enseigne de Vaisseau de 1ere Classe", "Lieutenant de Vaisseau"],
        "Officiers superieurs": ["Capitaine de Corvette", "Capitaine de Frégate", "Capitaine de Vaisseau"]
    },
    "Gendarmerie nationale": {
        "Militaires du Rang": ["Eleve gendarme", "Brigadier", "Brigadier Chef"],
        "Sous-officiers subalternes": ["Marechal des Logis", "Marechal des Logis Chef"],
        "Sous-officiers superieurs": ["Adjudant", "Adjudant-chef", "Major"],
        "Officiers subalternes": ["Aspirant (Ecole)", "Sous-lieutenant", "Lieutenant", "Capitaine"],
        "Officiers superieurs": ["Commandant ou Chef d’Escadron", "Lieutenant-colonel", "Colonnel"]
    }
}

class Administrative extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nombre_annee_service: this.props.nombre_annee_service || "",
            hierarchie: this.props.hierarchie || "",
            hierarchies: ["C", "B1", "B2", "A1", "A2", "A3"],
            hierarchie_armees: ["Militaires du Rang", "Sous-officiers subalternes", "Sous-officiers superieurs", "Officiers subalternes", "Officiers superieurs"],
            step_2: this.props.step_2 || false,
            departement: this.props.departement || "",
            departements: this.props.departements,
            service: this.props.service || "",
            fonction: this.props.fonction || "",
            ville_affectation: this.props.ville_affectation || "",
            villes: this.props.villes,
            quartier_affectation: this.props.quartier_affectation || "",
            corps_armee: this.props.corps_armee || "",
            grade: this.props.grade || "",
            corps_armees: ["Terre", "Air", "Mer", "Gendarmerie nationale"],
            error: false,
            m_defence: false,
            select_hierarchie: [],
            select_grades: []
        }
        this.handlerNbreAnneeService = this.handlerNbreAnneeService.bind(this)
        this.handlerChangeCorps = this.handlerChangeCorps.bind(this)
        this.handlerChangerHierarchieArmee = this.handlerChangerHierarchieArmee.bind(this)

    }

    handlerNbreAnneeService(e) {
        const valeur = e.target.value
        if (valeur <= 50) {
            this.setState({ nombre_annee_service: valeur })
            this.setState({ error: false })
        } else {
            this.setState({ error: true })
        }
    }

    componentDidMount() {
        if (this.props.matricule.match('[1-9]{1}[0-9]{4}[\/]{1}[A-Za-z]{1}') !== null) {
            this.setState({ m_defence: true })
            if (this.state.corps_armee !== "" && this.state.hierarchie !== "") {
                const hierarchies = grades_list[this.state.corps_armee]
                const select_grades = hierarchies[this.state.hierarchie]
                this.setState({ select_grades })
                this.setState({ select_hierarchie: hierarchies })
            }
        }
    }

    handlerChangeCorps(e) {
        const value = e.target.value
        if (value) {
            this.setState({ select_hierarchie: grades_list[value] })
        }
    }

    handlerChangerHierarchieArmee(e) {
        const value = e.target.value
        if (value) {
            this.setState({ corps_armee: "" })
            this.setState({ select_grades: this.state.select_hierarchie[value] })
        }
    }


    render() {
        const { hierarchie, select_grades, step_2, hierarchie_armees, corps_armee, grade, corps_armees, departement, service, fonction, ville_affectation, quartier_affectation } = this.state
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Nombre d'années de service restant </label>
                            <input type="number" className="form-control" placeholder="Nombre d'année de service restant"
                                name="demande_logement[nombre_annee_service]" min="1" max="50"
                                defaultValue={this.state.nombre_annee_service}
                                onChange={this.handlerNbreAnneeService} />
                            {this.state.error === true && <span className="mt-1" style={{ fontStyle: "italic", color: "#f59090" }}>
                                Le nombre de l'année de service restant doit être compris entre [1 - 50].</span>}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        {this.state.m_defence === false &&
                            <div className="form-group">
                                <label htmlFor="email">Hiérarchie * </label>
                                <select className="form-control" name="demande_logement[hierarchie]" required="required" defaultValue={hierarchie}>
                                    <option value="" >Sélectionner une hierarchie</option>
                                    {this.state.hierarchies.map((h, i) => <option key={i} value={h}>{h}</option>)}
                                </select>
                                <div className="invalid-feedback">La hiérarchie est obligatoire.</div>
                            </div>
                        }
                        {this.state.m_defence === true &&
                            <div className="form-group">
                                <label htmlFor="corps"> Corps d'armées *</label>
                                <select className="form-control" name="demande_logement[corps_armee]" defaultValue={corps_armee} onChange={this.handlerChangeCorps} required="required">
                                    <option value="">Sélectionner un corps d'armées</option>
                                    {corps_armees.map((c, i) => <option key={i} value={c}>{c}</option>)}
                                </select>
                                <div className="invalid-feedback">Le corps d'armées  est obligatoire.</div>
                            </div>
                        }
                    </div>
                </div>
                {this.state.m_defence === true &&
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="grade">Hiérarchie *</label>
                                <select name="demande_logement[hierarchie]" className="form-control" defaultValue={hierarchie} onChange={this.handlerChangerHierarchieArmee} required="required">
                                    <option value="">Sélectionner une hiérarchie</option>
                                    {hierarchie_armees.map((ha, i) => <option key={i} value={ha}>{ha}</option>)}
                                </select>
                                <div className="invalid-feedback">La hiérarchie est obligatoire.</div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="grade">Grade * </label>
                                <select name="demande_logement[grade]" className="form-control" defaultValue={grade} required="required">
                                    <option value="">Sélectionner un garde</option>
                                    {select_grades.map((g, i) => <option key={i} value={g}>{g}</option>)}
                                </select>
                                <div className="invalid-feedback">Le grade est obligatoire.</div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Département * </label>
                            <select className="form-control" name="demande_logement[departement]" required="required" defaultValue={departement}>
                                <option value="">Sélectionner un departement</option>
                                {this.state.departements.map((d, i) => <option key={i} value={d.nom}>{d.nom}</option>)}
                            </select>
                            <div className="invalid-feedback">Le departement est obligatoire.</div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Service * </label>
                            <input type="text" name="demande_logement[service]" defaultValue={service} className="form-control" required={true} />
                            <div className="invalid-feedback">Le service est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Fonction * </label>
                            <input type="text" name="demande_logement[fonction]" defaultValue={fonction} className="form-control" required={true} />
                            <div className="invalid-feedback">La fonction est obligatoire.</div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Ville d'affectation * </label>
                            <select className="form-control" name="demande_logement[ville_affectation]" defaultValue={ville_affectation} required={true}>
                                <option value="">Sélectionner une ville</option>
                                {this.state.villes.map((v, i) => <option key={i} value={v.nom}>{v.nom}</option>)}
                            </select>
                            <div className="invalid-feedback">La ville d'affectation est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Quartier d'affectation </label>
                            <input type="text" name="demande_logement[quartier_affectation]" className="form-control" />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

Administrative.propTypes = {
    nombre_annee_service: PropTypes.number,
    hierarchie: PropTypes.any,
    step_2: PropTypes.any,
    departement: PropTypes.string,
    departements: PropTypes.any,
    service: PropTypes.string,
    fonction: PropTypes.string,
    ville_affectation: PropTypes.any,
    quartier_affectation: PropTypes.string,
    villes: PropTypes.any,
    matricule: PropTypes.string,
    corps_armee: PropTypes.any,
    grade: PropTypes.any,
}


export default Administrative