import React from "react"
import PropTypes from "prop-types"
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'

const genres = ["Masculin","Féminin"]
class Filiation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date_naiss: this.props.dateNaiss === ''? '': new Date(`${this.props.dateNaiss.replace(/ UTC/, '')}`),
      pays_naiss: this.props.paysNaiss || 'Guinée',
      ville_naiss: this.props.villeNaiss,
      genre: this.props.genre || ''
    };

    this.paysNaissanceChange = this.paysNaissanceChange.bind(this);
    this.villeNaissanceChange = this.villeNaissanceChange.bind(this);
    this.genre = this.genreChange.bind(this)
  }

  paysNaissanceChange(event) {
    this.setState({pays_naiss: event.target.value});
  }

  villeNaissanceChange(event) {
    this.setState({ville_naiss: event.target.value});
  }

  genreChange(event){
    this.setState({genre:event.target.value});
  }

  render () {
    const { date_naiss, pays_naiss, ville_naiss, genre} = this.state
    return (
      <React.Fragment>
        <div className="row">
            <div className="col-sm-6">
            <div className="form-group">
                <label htmlFor="email">Genre *</label>
                <select defaultValue = {genre} className="form-control" name="genre" required={true}>
                    <option value="">Sélectionner genre</option>
                    {
                      genres.map((g, index) =>
                        (<option key={index} value={g}>{g}</option>)
                      )
                    }
                </select>
                <div className="invalid-feedback">Le genre est obligatoire.</div>
              </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email">Date de naissance * </label>
              <Flatpickr
                options={{
                  maxDate: new Date().fp_incr(-6600),
                  altInput: true,
                  altFormat: "j F Y",
                  dateFormat: "Y-m-d",
                  disableMobile: true
                }}
                className="form-control read-input"
                value={date_naiss}
                required={true}
                onChange={date_naiss => { this.setState({date_naiss}) }}
                onKeyPress={() => false}
                name="demande_logement[date_naiss]"
                id="demande_logement_date_naiss"
                placeholder="Selectionner une date"
              />
              <div id="id-invalid-date-naiss" className="invalid-feedback">La date de naissance est obligatoire.</div>
            </div>
          </div>
          
        </div>
        <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
              <label htmlFor="email">Pays de naissance * </label>
              <select className="form-control" name="pays_naiss" defaultValue={pays_naiss} required={true} onChange={this.paysNaissanceChange}>
                <option value="" >Sélectionner un pays</option>
                {
                  this.props.pays.map(p => (
                    <option key={p.id} value={p.nom}>{p.nom}</option>
                  ))
                }
              </select>
              <div className="invalid-feedback">Le pays de naissance est obligatoire.</div>
            </div>
          </div>  
          <div className="col-sm-6">
              {
                pays_naiss !== 'Guinée'?
                  <div className="form-group">
                    <label htmlFor="email">Ville de naissance * </label>
                    <input type='text' value={ville_naiss} className='form-control' name='demande_logement[ville_naiss]' required={true} onChange={this.villeNaissanceChange}/>
                    <div className="invalid-feedback">La ville de naissance est obligatoire.</div>
                  </div>:
                  <div className="form-group">
                    <label htmlFor="email">Ville de naissance * </label>
                    <select className="form-control" name="demande_logement[ville_naiss]" defaultValue={ville_naiss} required={true} onChange={this.selctVilleNaissanceChange}>
                      <option value="" >Sélectionner une ville</option>
                      {
                        this.props.villes.map(v => (
                          <option key={v.id} value={v.nom}>{v.nom}</option>
                        ))
                      }
                    </select>
                    <div className="invalid-feedback">La ville de naissance est obligatoire.</div>
                  </div>
              }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Filiation.propTypes = {
  dateNaiss: PropTypes.node,
  paysNaiss: PropTypes.string,
  villeNaiss: PropTypes.string,
  pays: PropTypes.any,
  villes: PropTypes.any,
  genre: PropTypes.string
};
export default Filiation
