import React from "react"
import PropTypes from "prop-types"
const contrats = [{
    type: "CTT",
    name: "Contrat de Travail Temporaire"
},
{
    type: "CDD",
    name: "Contrat de travail à Durée Déterminée"
},
{
    type: "CDI",
    name: "Contrat de travail à Durée Indéterminée"
}]

const secteur_activites = [
    {
        id: `secteur_1`,
        name: "Agroalimentaire"
    },
    {
        id: `secteur_2`,
        name: "Banque / Assurance"
    },
    {
        id: `secteur_3`,
        name: "Bois / Papier / Carton / Imprimerie"
    },
    {
        id: `secteur_4`,
        name: "BTP / Matériaux de construction"
    },
    {
        id: `secteur_5`,
        name: "Chimie / Parachimie"
    },
    {
        id: `secteur_6`,
        name: "Commerce / Négoce / Distribution"
    },
    {
        id: `secteur_7`,
        name: "Édition / Communication / Multimédia"
    },
    {
        id: `secteur_8`,
        name: "Électronique / Électricité"
    },
    {
        id: `secteur_9`,
        name: "Études et conseils"
    },
    {
        id: `secteur_10`,
        name: "Industrie pharmaceutique"
    },
    {
        id: `secteur_11`,
        name: "Informatique / Télécoms"
    },
    {
        id: `secteur_12`,
        name: "Machines et équipements / Automobile"
    },
    {
        id: `secteur_13`,
        name: "Métallurgie / Travail du métal"
    },
    {
        id: `secteur_14`,
        name: "Plastique / Caoutchouc"
    },
    {
        id: `secteur_15`,
        name: "Services aux entreprises"
    },
    {
        id: `secteur_16`,
        name: "Textile / Habillement / Chaussure"
    },
    {
        id: `secteur_17`,
        name: "Transports / Logistique"
    },
]

class AdministrativePrive extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            step_2: this.props.step_2 || false,
            entreprise: this.props.entreprise || "",
            type_contrat: this.props.type_contrat || "",
            secteur_activite: this.props.secteur_activite || "",
            fonction: this.props.fonction || "",
            ville_affectation: this.props.ville_affectation || "",
            quartier_affectation: this.props.quartier_affectation || "",
            villes: this.props.villes,
        }
    }

    render() {
        const { entreprise, type_contrat, secteur_activite, fonction, ville_affectation, villes, quartier_affectation } = this.state
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="demande_logement_entreprise">Entreprise * </label>
                            <input type="text" className="form-control" placeholder="Le nom de votre entreprise"
                                name="demande_logement[entreprise]" required="required"
                                defaultValue={entreprise} />
                            <div className="invalid-feedback">Le nom de votre entreprise est obligatoire.</div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="demande_logement_secteur_activite">Secteur d'activité * </label>
                            <select className="form-control" name="demande_logement[secteur_activite]" required="required" defaultValue={secteur_activite}>
                                <option value="" >Sélectionner un secteur d'activité</option>
                                {secteur_activites.map(s => <option key={s.id} value={s.name}>{s.name}</option>)}
                            </select>
                            <div className="invalid-feedback">Le secteur d'activité est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="demande_logement_type_contrat">Type de Contrat * </label>
                            <select className="form-control" name="demande_logement[type_contrat]" required="required" defaultValue={type_contrat}>
                                <option value="" >Sélectionner un type de contrat</option>
                                {contrats.map((h, i) => <option key={i} value={h.type}>{h.name}</option>)}
                            </select>
                            <div className="invalid-feedback">Le type de contrat est obligatoire.</div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Fonction * </label>
                            <input type="text" name="demande_logement[fonction]" defaultValue={fonction} className="form-control" required={true} />
                            <div className="invalid-feedback">La fonction est obligatoire.</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Ville d'affectation * </label>
                            <select className="form-control" name="demande_logement[ville_affectation]" defaultValue={ville_affectation} required={true}>
                                <option value="">Sélectionner une ville</option>
                                {villes.map((v, i) => <option key={i} value={v.nom}>{v.nom}</option>)}
                            </select>
                            <div className="invalid-feedback">La ville d'affectation est obligatoire.</div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label htmlFor="email">Quartier d'affectation </label>
                            <input type="text" name="demande_logement[quartier_affectation]"
                                className="form-control" defaultValue={quartier_affectation} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

AdministrativePrive.propTypes = {
    step_2: PropTypes.any,
    fonction: PropTypes.string,
    secteur_activite: PropTypes.string,
    ville_affectation: PropTypes.string,
    quartier_affectation: PropTypes.string,
    villes: PropTypes.any,
    matricule: PropTypes.string
}


export default AdministrativePrive
