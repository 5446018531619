export const STATUS_ENCOURS = 'Encours'
export const STATUS_TRAITE = 'Traité'
export const STATUS_SUPPRIME = 'Supprimé'

export function delaisDay(startDate, endDate) {
    let diffInMilliSeconds = (endDate - startDate) / 1000
    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);

    if (days > 0)
        return [false, days]
    else
        return [true, Math.abs(days)]
}

export function timeDiffCalc(endDate, todayDate) {
    let diffInMilliSeconds = Math.abs(endDate - todayDate) / 1000
    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate secondes
    const secondes = Math.trunc(diffInMilliSeconds % 60);

    let difference = '';
    if (days > 0) {
      difference += (days === 1) ? `${days} jour, ` : `${days} jours, `;
    }

    difference += (hours === 0 || hours === 1) ? `${hours} heure, ` : `${hours} heures, `;

    difference += (minutes === 0 || minutes === 1) ? `${minutes} minute, ` : `${minutes} minutes, `; 

    difference += (secondes === 0 || secondes === 1) ? `${secondes} seconde` : `${secondes} secondes`; 

    return [difference, days];
}