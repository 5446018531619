import React from "react"
import {timeDiffCalc, delaisDay, STATUS_ENCOURS, STATUS_SUPPRIME, STATUS_TRAITE} from "../utils/functions"

function AssignationListe({assignations}) {
    
    console.log(assignations)
    return (
        <div>
            <table id="dttb-souscription" className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Assigné(s)</th>
                        <th>Délai Aloué (jours)</th>
                        <th>Temps Restant</th>
                        <th>Status</th>
                        <th>Actionsn</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        assignations.map((a,index)=>{
                            const delais_atteint = a.status === STATUS_ENCOURS && delaisDay(new Date(a.created_at), new Date(a.ending_at))[0]
                            return (
                            <tr key={a.id} className={delais_atteint ? "bg-danger text-white" : ""} >
                                <td>{ index + 1 }</td>
                                <td>{ a.recipient}</td>
                                <td>{ a.default_delay} jour.s</td>
                                <td>{delais_atteint || a.status === STATUS_TRAITE || a.status === STATUS_SUPPRIME ? '0 seconde' : timeDiffCalc(new Date(a.ending_at), new Date())[0] }</td>
                                <td>{ a.status }</td>
                                <td className = "text-center">
                                    {
                                        a.status === STATUS_ENCOURS ?
                                        <a href={"/assignation-delete/" + a.id} className = "btn btn-danger btn-sm"><i className="fa fa-trash"></i></a> : ""
                                    }
                                </td>
                            </tr>
                        )})
                    }
                </tbody>
            </table>
        </div>
    )
}


export default AssignationListe
