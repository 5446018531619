import React from "react"
import PropTypes from "prop-types"
import NumberFormat from 'react-number-format';
import SelectWrap from './selectWrap'
import Projet from './Projet';

function monthlypayement(rate, numberOfMonths, amount) {
    return Math.round([amount * rate] / [1 - Math.pow((1 + rate), -numberOfMonths)] * 100) / 100
}

function getHabitationsDiff(projets, prj='', secPrj='', secHbt='', terdPrj='', terdHbt='') {
    if (!projets.length || prj === '') return []
    if(prj === secPrj) {
        if(prj === terdPrj) {
            return projets.filter(pr => pr.hashid === prj)[0]
                .habitations
                .filter(h => h.hashid !== secHbt)
                .filter(h => h.hashid !== terdHbt)
        } else {
            return projets.filter(pr => pr.hashid === prj)[0]
                .habitations.filter(h => h.hashid !== secHbt)
        }
    } else {
        if(prj === terdPrj) {
            return projets.filter(pr => pr.hashid === prj)[0]
                .habitations.filter(h => h.hashid !== terdHbt)
        } else {
            return projets.filter(pr => pr.hashid === prj)[0]
                .habitations
        }
    }
}

function getProjectToSelect(projets, prj='', habit='', secPrj='', secHabit='') {
    if (!projets.length) return []
    if (prj === '') return projets
            .map(p => ({hashid: p.hashid, nom: p.nom}))

    let habits_check
    if(prj === secPrj) {
        habits_check = projets.filter(pr => pr.hashid === prj)[0]
            .habitations
            .filter(h => h.hashid !== habit)
            .filter(h => h.hashid !== secHabit)
    } else {
        habits_check = projets.filter(pr => pr.hashid === prj)[0]
            .habitations
            .filter(h => h.hashid !== habit)
    }

    if (habits_check.length === 0){
        return projets
            .filter(pr => pr.hashid !== prj)
            .map(p => ({hashid: p.hashid, nom: p.nom}))
    }

    return projets
            .map(p => ({hashid: p.hashid, nom: p.nom}))
}

function getLogementPrice(projets, prj='', habit='') {
    if (!projets.length || prj === '' || habit === '') return 0
    return projets.filter(pr => pr.hashid === prj)[0]
            .habitations
            .filter(h => h.hashid === habit)[0].prix
}

class LogementDemande extends React.Component {
    state = {
        all_projects: [],

        poucent_permis: 0.4,
        periode_mois: 25,

        simulateur: [],
        

        net_price_2: 0,
        engagement_permis_2: 0,
        senario_actuel_2: 0,

        net_price_3: 0,
        engagement_permis_3: 0,
        senario_actuel_3: 0,
        montant_apport: this.props.montant_apport || 0,
        total_revenu: this.props.total_revenu || 1
        
    };

    componentDidMount() {
        const {projets} = this.props
        const all_projects = []
        const simulateur = []

        this.props.projets_init.map((prj, i) => {
            if(i===0) {
                all_projects.push({
                    index: new Date().getTime()+1,
                    projet: prj.projet,
                    projet_to_select: [],
                    habitation_id: `${prj.habitation_id}_${prj.model_logement_id}`,
                    habitations: []
                })
            }
            if(i===1) {
                all_projects.push({
                    index: new Date().getTime()+1,
                    projet: prj.projet,
                    projet_to_select: [],
                    habitation_id: `${prj.habitation_id}_${prj.model_logement_id}`,
                    habitations: []
                })
            }
            if(i===2) {
                all_projects.push({
                    index: new Date().getTime()+1,
                    projet: prj.projet,
                    projet_to_select: [],
                    habitation_id: `${prj.habitation_id}_${prj.model_logement_id}`,
                    habitations: []
                })
            }
        })

        if (all_projects.length === 1){
            all_projects[0].habitations = getHabitationsDiff(
                projets, all_projects[0].projet
            )
            all_projects[0].projet_to_select = getProjectToSelect(projets)

            const net_price = getLogementPrice(
                projets,
                all_projects[0].projet,
                all_projects[0].habitation_id
            ) - this.state.montant_apport

            const engagement_permis = this.state.total_revenu * this.state.poucent_permis
            const senario_actuel = monthlypayement(0.06 / 12, this.state.periode_mois * 12, net_price)
            const apport_mensuel = (senario_actuel - engagement_permis) <= 0 ? 0 : senario_actuel - engagement_permis
            const apport_global = apport_mensuel * this.state.periode_mois * 12

            simulateur.push({
                net_price: net_price,
                engagement_permis,
                senario_actuel,
                apport_global
            })

            all_projects.push(...[{
                index: new Date().getTime() + 1,
                projet: '',
                projet_to_select: getProjectToSelect(projets) || [],
                habitation_id: '',
                habitations: []
            },
            {
                index: new Date().getTime() + 2,
                projet: '',
                projet_to_select: getProjectToSelect(projets) || [],
                habitation_id: '',
                habitations: []
            }])
            
            simulateur.push(...[{
                net_price: 0,
                engagement_permis: 0,
                senario_actuel: 0,
                apport_global: 0
            },
            {
                net_price: 0,
                engagement_permis: 0,
                senario_actuel: 0,
                apport_global: 0
            }])
        }


        if (all_projects.length === 2){
            all_projects[0].projet_to_select = getProjectToSelect(
                projets,
                all_projects[1].projet,
                all_projects[1].habitation_id
            )

            all_projects[0].habitations = getHabitationsDiff(
                projets,
                all_projects[0].projet,
                all_projects[1].projet,
                all_projects[1].habitation_id
            )

            all_projects[1].projet_to_select = getProjectToSelect(
                projets,
                all_projects[0].projet,
                all_projects[0].habitation_id
            )
            
            all_projects[1].habitations = getHabitationsDiff(
                projets,
                all_projects[1].projet,
                all_projects[0].projet,
                all_projects[0].habitation_id
            )

            let net_price = getLogementPrice(
                projets,
                all_projects[0].projet,
                all_projects[0].habitation_id
            ) - this.state.montant_apport
            
            let engagement_permis = this.state.total_revenu * this.state.poucent_permis
            let senario_actuel = monthlypayement(0.06 / 12, this.state.periode_mois * 12, net_price)
            let apport_mensuel = (senario_actuel - engagement_permis) <= 0 ? 0 : senario_actuel - engagement_permis
            let apport_global = apport_mensuel * this.state.periode_mois * 12

            simulateur.push({
                net_price: net_price,
                engagement_permis,
                senario_actuel,
                apport_global
            })

            net_price = getLogementPrice(
                projets,
                all_projects[1].projet,
                all_projects[1].habitation_id
            ) - this.state.montant_apport

            senario_actuel = monthlypayement(0.06 / 12, this.state.periode_mois * 12, net_price)
            apport_mensuel = (senario_actuel - engagement_permis) <= 0 ? 0 : senario_actuel - engagement_permis
            apport_global = apport_mensuel * this.state.periode_mois * 12

            simulateur.push({
                net_price: net_price,
                engagement_permis,
                senario_actuel,
                apport_global
            })


            all_projects.push({
                index: new Date().getTime(),
                projet: '',
                projet_to_select: getProjectToSelect(projets) || [],
                habitation_id: '',
                habitations: []
            })
            simulateur.push({
                net_price: 0,
                engagement_permis: 0,
                senario_actuel: 0,
                apport_global: 0
            })
        }

        if (all_projects.length === 3){
            all_projects[0].projet_to_select = getProjectToSelect(
                projets,
                all_projects[1].projet,
                all_projects[1].habitation_id,
                all_projects[2].projet,
                all_projects[2].habitation_id
            )

            all_projects[0].habitations = getHabitationsDiff(
                projets,
                all_projects[0].projet,
                all_projects[1].projet,
                all_projects[1].habitation_id,
                all_projects[2].projet,
                all_projects[2].habitation_id
            )

            all_projects[1].projet_to_select = getProjectToSelect(
                projets,
                all_projects[0].projet,
                all_projects[0].habitation_id,
                all_projects[2].projet,
                all_projects[2].habitation_id
            )
            
            all_projects[1].habitations = getHabitationsDiff(
                projets,
                all_projects[1].projet,
                all_projects[0].projet,
                all_projects[0].habitation_id,
                all_projects[2].projet,
                all_projects[2].habitation_id
            )

            all_projects[2].projet_to_select = getProjectToSelect(
                projets,
                all_projects[0].projet,
                all_projects[0].habitation_id,
                all_projects[1].projet,
                all_projects[1].habitation_id
            )
            
            all_projects[2].habitations = getHabitationsDiff(
                projets,
                all_projects[2].projet,
                all_projects[0].projet,
                all_projects[0].habitation_id,
                all_projects[1].projet,
                all_projects[1].habitation_id
            )


            let net_price = getLogementPrice(
                projets,
                all_projects[0].projet,
                all_projects[0].habitation_id
            ) - this.state.montant_apport

            let engagement_permis = this.state.total_revenu * this.state.poucent_permis
            let senario_actuel = monthlypayement(0.06 / 12, this.state.periode_mois * 12, net_price)
            let apport_mensuel = (senario_actuel - engagement_permis) <= 0 ? 0 : senario_actuel - engagement_permis
            let apport_global = apport_mensuel * this.state.periode_mois * 12

            simulateur.push({
                net_price: net_price,
                engagement_permis,
                senario_actuel,
                apport_global
            })

            net_price = getLogementPrice(
                projets,
                all_projects[1].projet,
                all_projects[1].habitation_id
            )

            senario_actuel = monthlypayement(0.06 / 12, this.state.periode_mois * 12, net_price)
            apport_mensuel = (senario_actuel - engagement_permis) <= 0 ? 0 : senario_actuel - engagement_permis
            apport_global = apport_mensuel * this.state.periode_mois * 12

            simulateur.push({
                net_price: net_price,
                engagement_permis,
                senario_actuel,
                apport_global
            })

            net_price = getLogementPrice(
                projets,
                all_projects[2].projet,
                all_projects[2].habitation_id
            ) - this.state.montant_apport
            
            senario_actuel = monthlypayement(0.06 / 12, this.state.periode_mois * 12, net_price)
            apport_mensuel = (senario_actuel - engagement_permis) <= 0 ? 0 : senario_actuel - engagement_permis
            apport_global = apport_mensuel * this.state.periode_mois * 12

            simulateur.push({
                net_price: net_price,
                engagement_permis,
                senario_actuel,
                apport_global
            })
        }

        if (all_projects.length === 0) {
            all_projects.push(...[{
                index: new Date().getTime() + 1,
                projet: '',
                projet_to_select: getProjectToSelect(projets) || [],
                habitation_id: '',
                habitations: []
            },
            {
                index: new Date().getTime()+ 2,
                projet: '',
                projet_to_select: getProjectToSelect(projets) || [],
                habitation_id: '',
                habitations: []
            },
            {
                index: new Date().getTime()+ 3,
                projet: '',
                projet_to_select: getProjectToSelect(projets) || [],
                habitation_id: '',
                habitations: []
            }])

            simulateur.push(...[{
                net_price: 0,
                engagement_permis: 0,
                senario_actuel: 0,
                apport_global: 0
            },
            {
                net_price: 0,
                engagement_permis: 0,
                senario_actuel: 0,
                apport_global: 0
            },
            {
                net_price: 0,
                engagement_permis: 0,
                senario_actuel: 0,
                apport_global: 0
            }])
        }

        this.setState({all_projects, simulateur})
    }

    handleProjetChange = (event, pjc) => {
        const {projets} = this.props
        const prj = event.target.value

        const all_projects = this.state.all_projects
        const simulateur = this.state.simulateur

        all_projects[pjc].projet = prj
        all_projects[pjc].habitation_id = ''

        if (all_projects.length === 1){
            all_projects[pjc].habitations = getHabitationsDiff(projets, prj)
        }

        if (all_projects.length === 2) {
            let secId = 0
            if(pjc === 0) secId = 1

            all_projects[pjc].habitations = getHabitationsDiff(
                projets, prj,
                all_projects[secId].projet,
                all_projects[secId].habitation_id
            )
            all_projects[secId].projet_to_select = getProjectToSelect(
                projets,
                prj,
                all_projects[pjc].habitation_id
            )
            all_projects[secId].habitations = getHabitationsDiff(
                projets,
                all_projects[secId].projet,
                prj,
                all_projects[pjc].habitation_id
            )

        }

        if (all_projects.length === 3) {
            let secId = 0
            let ThirdId = 0

            if (pjc === 0){
                secId = 1
                ThirdId = 2
            } else if (pjc === 1){
                secId = 0
                ThirdId = 2
            } else {
                secId = 0
                ThirdId = 1
            }

            all_projects[pjc].habitations = getHabitationsDiff(
                projets, prj,
                all_projects[secId].projet,
                all_projects[secId].habitation_id,
                all_projects[ThirdId].projet,
                all_projects[ThirdId].habitation_id
            )
            all_projects[secId].projet_to_select = getProjectToSelect(
                projets,
                prj,
                all_projects[pjc].habitation_id,
                all_projects[ThirdId].projet,
                all_projects[ThirdId].habitation_id
            )
            all_projects[secId].habitations = getHabitationsDiff(
                projets,
                all_projects[secId].projet,
                prj,
                all_projects[pjc].habitation_id,
                all_projects[ThirdId].projet,
                all_projects[ThirdId].habitation_id
            )
            all_projects[ThirdId].projet_to_select = getProjectToSelect(
                projets,
                prj,
                all_projects[pjc].habitation_id,
                all_projects[secId].projet,
                all_projects[secId].habitation_id
            )
            all_projects[ThirdId].habitations = getHabitationsDiff(
                projets,
                all_projects[ThirdId].projet,
                prj,
                all_projects[pjc].habitation_id,
                all_projects[secId].projet,
                all_projects[secId].habitation_id
            )
            
        }

        simulateur[pjc].net_price = 0
        simulateur[pjc].engagement_permis = 0
        simulateur[pjc].senario_actuel = 0
        simulateur[pjc].apport_global = 0

        this.setState({all_projects, simulateur})
    }

    handleHabtationChange = (event, tl) => {
        const {projets} = this.props
        const all_projects = this.state.all_projects
        const simulateur = this.state.simulateur
        const habit = event.target.value

        if (all_projects.length === 2){
            let secId = 0
            if(tl === 0) secId = 1

            all_projects[secId].projet_to_select = getProjectToSelect(
                projets,
                all_projects[tl].projet,
                habit
            )

            all_projects[secId].habitations = getHabitationsDiff(
                projets,
                all_projects[secId].projet,
                all_projects[tl].projet,
                habit
            )
        }
        
        if (all_projects.length === 3){
            let secId = 0
            let ThirdId = 0

            if (tl === 0){
                secId = 1
                ThirdId = 2
            } else if (tl === 1){
                secId = 0
                ThirdId = 2
            } else {
                secId = 0
                ThirdId = 1
            }

            all_projects[secId].projet_to_select = getProjectToSelect(
                projets,
                all_projects[tl].projet,
                habit,
                all_projects[ThirdId].projet,
                all_projects[ThirdId].habitation_id
            )

            all_projects[secId].habitations = getHabitationsDiff(
                projets,
                all_projects[secId].projet,
                all_projects[tl].projet,
                habit,
                all_projects[ThirdId].projet,
                all_projects[ThirdId].habitation_id
            )

            all_projects[ThirdId].projet_to_select = getProjectToSelect(
                projets,
                all_projects[tl].projet,
                habit,
                all_projects[secId].projet,
                all_projects[secId].habitation_id
            )

            all_projects[ThirdId].habitations = getHabitationsDiff(
                projets,
                all_projects[ThirdId].projet,
                all_projects[tl].projet,
                habit,
                all_projects[secId].projet,
                all_projects[secId].habitation_id
            )
            
        }

        const net_price = getLogementPrice(
            projets,
            all_projects[tl].projet,
            habit
        ) - this.state.montant_apport

        const engagement_permis = this.state.total_revenu * this.state.poucent_permis
        const senario_actuel = monthlypayement(0.06 / 12, this.state.periode_mois * 12, net_price)
        const apport_mensuel = (senario_actuel - engagement_permis) <= 0 ? 0 : senario_actuel - engagement_permis
        const apport_global = apport_mensuel * this.state.periode_mois * 12

        simulateur[tl].net_price = net_price
        simulateur[tl].engagement_permis = engagement_permis
        simulateur[tl].senario_actuel = senario_actuel
        simulateur[tl].apport_global = apport_global

        all_projects[tl].habitation_id = habit

        this.setState({all_projects, simulateur})
    }

    handleAddProject = () => {
        const all_projects = this.state.all_projects
        const simulateur = this.state.simulateur

        let projets_to_select = []

        if (all_projects.length === 1) {

            projets_to_select = getProjectToSelect(
                this.props.projets,
                all_projects[0].projet,
                all_projects[0].habitation_id
            )
        }
        if (all_projects.length === 2) {

            projets_to_select = getProjectToSelect(
                this.props.projets,
                all_projects[0].projet,
                all_projects[0].habitation_id,
                all_projects[1].projet,
                all_projects[1].habitation_id
            )
        }

        simulateur.push({
            net_price: 0,
            engagement_permis: 0,
            senario_actuel: 0,
            apport_global: 0
        })

        all_projects.push({
            index: new Date().getTime(),
            projet: '',
            projet_to_select: projets_to_select,
            habitation_id: '',
            habitations: [],
        })
        this.setState({all_projects, simulateur})
    }

    handleSuppProject = (tl) => {
        const all_projects = [
            ...this.state.all_projects.slice(0, tl),
            ...this.state.all_projects.slice(tl+1)
        ]

        const simulateur = [
            ...this.state.simulateur.slice(0, tl),
            ...this.state.simulateur.slice(tl+1)
        ]

        if (all_projects.length === 1) {
            all_projects[0].projet_to_select = this.props.projets
                .map(p => ({hashid: p.hashid, nom: p.nom}))
            all_projects[0].habitations = getHabitationsDiff(
                this.props.projets,
                all_projects[0].projet
            )
        }

        if (all_projects.length === 2) {
            all_projects[0].projet_to_select = getProjectToSelect(
                this.props.projets,
                all_projects[0].projet,
                all_projects[0].habitation_id,
                all_projects[1].projet,
                all_projects[1].habitation_id
            )

            all_projects[0].habitations = getHabitationsDiff(
                this.props.projets,
                all_projects[0].projet,
                all_projects[1].projet,
                all_projects[1].habitation_id
            )

            all_projects[1].projet_to_select = getProjectToSelect(
                this.props.projets,
                all_projects[1].projet,
                all_projects[1].habitation_id,
                all_projects[0].projet,
                all_projects[0].habitation_id
            )

            all_projects[1].habitations = getHabitationsDiff(
                this.props.projets,
                all_projects[1].projet,
                all_projects[0].projet,
                all_projects[0].habitation_id
            )
        }

        this.setState({all_projects, simulateur})
    }

    handleNombreMoisChange = (event, ind) => {
        const {projets} = this.props
        const nb_mois = parseInt(event.target.value)
        const simulateur = this.state.simulateur

        const net_price = getLogementPrice(
            projets,
            this.state.all_projects[ind].projet,
            this.state.all_projects[ind].habitation_id
        ) - this.state.montant_apport

        const engagement_permis = this.state.total_revenu * this.state.poucent_permis
        const senario_actuel = monthlypayement(0.06 / 12, nb_mois * 12, net_price)
        const apport_mensuel = (senario_actuel - engagement_permis) <= 0 ? 0 : senario_actuel - engagement_permis
        const apport_global = apport_mensuel * nb_mois * 12

        simulateur[ind].engagement_permis = engagement_permis
        simulateur[ind].senario_actuel = senario_actuel
        simulateur[ind].apport_global = apport_global

        this.setState({ simulateur })
    }

    render() {
            const {
                all_projects, simulateur, montant_apport
            } = this.state

            return (
                <React.Fragment>
                    <div className="panel panel-default in">
                        <div className="panel-heading" role="tab" id="Filliations" >
                            <h4 className="panel-title" >
                                <a className="collapsed" role="button" data-toggle = "collapse" data-parent = "#accordion" href = "#filiationsCollapse" aria-expanded = "true" aria-controls = "filiationsCollapse">
                                    <i className="more-less fa fa-chevron-down" > </i> Logement demandé 
                                </a>
                            </h4>
                        </div>
                        <div id = "filiationsCollapse" className = "panel-collapse collapse show" role = "tabpanel" aria-labelledby = "Filliations">
                            <div className = "panel-body">

                                <div className="row">
                                    {
                                        all_projects.map((pjc,i) => (
                                            <div className="col-sm-4 border-right" key={`projet_${pjc.index}${i}`}>
                                                <div className = "form-group">
                                                    <label htmlFor="email"> Projet {`${i + 1}`} * </label>
                                                    <select required={true} className="form-control" defaultValue={pjc.projet} name={`demande_logement[projet_${i + 1}]`} id={`demande_logement_projet_${pjc.index}`} onChange={ event => this.handleProjetChange(event, i) }>
                                                        <option value=""> ---Sélectionner un projet---  </option>
                                                        {
                                                            pjc.projet_to_select.map(prj => (
                                                                <option key={`projet-${pjc.index}-${prj.hashid}`} value={prj.hashid}>  { prj.nom }</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback"> Le projet numéro {`${i + 1}`} est obligatoire.</div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="row">
                                    {
                                        all_projects.map((tl, i) => (
                                            <div className="col-sm-4 border-right" key={`type_log_${tl.index}${i}`}>
                                                <div className = "form-group">
                                                    <label htmlFor="email"> Choix {`${i + 1}`} * </label>
                                                    <select required={true} className="form-control" defaultValue={ tl.habitation_id } name={`demande_logement[habitation_${i + 1}]`} id={`demande_logement_habitation_${tl.index}`} onChange={ event => this.handleHabtationChange(event, i) } >
                                                        <option value=""> ---Sélectionner un choix --- </option>
                                                        {
                                                            tl.habitations.map(tpl => (
                                                                <option key={`habit-${tl.index}-${tpl.hashid}`} value={tpl.hashid}>
                                                                    { tpl.nom_habitation } -- {tpl.nom_tp_loge} ({ Number(tpl.prix.toFixed(0)).toLocaleString() } GNF)
                                                                </option>
                                                            ))
                                                        } 
                                                    </select>
                                                    <div className="invalid-feedback"> L'habitation demandé numéro {`${i + 1}`} est obligatoire.</div>
                                                    
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className = "row" >
                                    <div className = "col-sm-6" >
                                        <div className = "form-group" >
                                            <label htmlFor = "email" > Capacité maximale d’ emprunt * </label>
                                            <div className = "font-weight-bold">
                                                <NumberFormat value = { this.props.capacite_emprunt || 0 } decimalScale={2} displayType = { 'text' } thousandSeparator = { ' ' } suffix = { ' GNF' }/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default in" >
                        <div className="panel-heading" role="tab" id="Simulation" >
                            <h4 className="panel-title" >
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#simulationsCollapse" aria-expanded="true" aria-controls="simulationsCollapse" >
                                    <i className="more-less fa fa-chevron-down"></i> Mensualité pour ce choix 
                                </a>
                            </h4>
                        </div>
                        <div id="simulationsCollapse" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="Simulation">
                            <div className="panel-body">
                                {
                                    simulateur.map((sim, i) => (
                                        <div key={`simulateur_${i}`}>
                                            <h5>Mensualité Projet {i+1}</h5>
                                            <div className = "row">
                                                <div className = "col-sm-3">
                                                    <div className = "form-group">
                                                        <label htmlFor = "email"> Nombre d’ année de paiement </label>
                                                        <div className = "font-weight-bold" >
                                                            <select className = "form-control" defaultValue = { 25 } onChange = { event => this.handleNombreMoisChange(event, i) } > 
                                                                { Array.from(Array(20), (_, i) => i+5).map((y, index) => <option key={index} value={y}> {y} ans </option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className = "col-sm-3" >
                                                    <div className = "form-group" >
                                                        <label htmlFor = "email" > Mensualité permis en % </label>
                                                        <div className = "font-weight-bold"> 40 % </div>
                                                    </div>
                                                </div>

                                                <div className = "col-sm-3" >
                                                    <div className = "form-group" >
                                                        <label htmlFor = "email" > Mensualité permis </label>
                                                        <div className = "font-weight-bold" >
                                                            <NumberFormat value={ sim.engagement_permis} decimalScale={2} displayType={'text'} thousandSeparator={' '} suffix={' GNF'}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className = "col-sm-3" >
                                                    <div className = "form-group" >
                                                        <label htmlFor = "email" > Mensualité pour ce choix </label>
                                                        <div className = "font-weight-bold" >
                                                            <NumberFormat value={ Math.round( sim.senario_actuel * 100) / 100} decimalScale={2} displayType={'text'} thousandSeparator={' '} suffix={' GNF'}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default in" >
                        <div className="panel-heading" role="tab" id="Apport" >
                            <h4 className="panel-title" >
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#ApportsCollapse" aria-expanded="true" aria-controls="ApportsCollapse" >
                                    <i className="more-less fa fa-chevron-down"></i> Apport personnel
                                </a>
                            </h4>
                        </div>
                        <div id="ApportsCollapse" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="Apport">
                            <div className="panel-body">
                                <div className = "row">
                                    {
                                        simulateur.map((sim, i) => (
                                            <div key={`simulateur_${i}`} className = "col-sm-4" >
                                                <div className = "form-group" >
                                                    <label htmlFor = "email" > Apport Global requis Projet {i+1} </label>
                                                    <div className = "font-weight-bold" >
                                                        <NumberFormat value={ Math.round( sim.apport_global * 100) / 100 } decimalScale={2} displayType={'text'} thousandSeparator={' '} suffix={' GNF'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="email">Montant de l’apport (GNF) </label><br/>
                                            <em>Somme d’argent disponible pour le projet en cours</em>
                                            <input type="number" name="demande_logement[montant_apport]" value={montant_apport} className="form-control" required={true} onChange={event => this.setState({montant_apport: event.target.value.replace(/\D/,'')})} />
                                            <div className="invalid-feedback">Le montant de l'apport est obligatoire et doit être compris entre [0 - 2.000.000.000].</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            )
    }
}

LogementDemande.propTypes = {
    hashid: PropTypes.string,
    capacite_emprunt: PropTypes.number,
    projets: PropTypes.any,
    projets_init: PropTypes.any,
};
export default LogementDemande