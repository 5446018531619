// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('datatables.net-bs4')
require('datatables.net-buttons-bs4')
require('datatables.net-responsive-bs4')
require("chartkick")
require("chart.js")
require("trix")
require("@rails/actiontext")

import $ from 'jquery';
global.$ = jQuery;

import flatpickr from 'flatpickr'

require('select2')
require('flatpickr/dist/flatpickr.css')
require('flatpickr/dist/themes/material_blue.css')
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css'
import 'select2/dist/css/select2.css';  // optional if you have css loader

import 'bootstrap'
import "@fortawesome/fontawesome-free/js/all"
import '../stylesheets/application'

import toastr from 'toastr'
window.toastr = toastr

var t = null

document.addEventListener("turbolinks:load", () => {
    $(window).on('scroll', function () {
        // Sticky Navbar
        if ($(this).scrollTop() > 45) {
            $('.nav-bar').addClass('sticky-top');
            $('.nav-bar-2').addClass('sticky-top');
        } else {
            $('.nav-bar').removeClass('sticky-top');
            $('.nav-bar-2').removeClass('sticky-top');
        }
        
        // Back to top button
        if ($(this).scrollTop() > 300) {
            $('.back-to-top').fadeIn('slow');
        } else {
            $('.back-to-top').fadeOut('slow');
        }
    });

    // Back to top button
    $('.back-to-top').on('click', function () {
        $('html, body').animate({scrollTop: 0}, 1500);
        return false;
    });

    const French = require("flatpickr/dist/l10n/fr.js").default.fr;
    flatpickr.localize(French); // default locale is now Russian

    flatpickr("[data-behavior='flatpickr']", {
        maxDate: new Date().fp_incr(-0),
        altInput: true,
        altFormat: "j F Y",
        dateFormat: "Y-m-d",
        disableMobile: true,
        altInputClass: 'form-control read-input',
    })

    flatpickr("#search_date", {
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disableMobile: true,
        mode: "range",
        altInputClass: 'form-control read-input',
    })

    flatpickr("#date", {
        maxDate: new Date().fp_incr(-0),
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disableMobile: true,
        mode: "range",
        altInputClass: 'form-control read-input',
    })

    flatpickr("#date_de_debut1", {
        minDate: new Date().fp_incr(0),
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disableMobile: true,
        altInputClass: 'form-control read-input',
    })

    flatpickr("#date_de_debut2", {
        minDate: new Date().fp_incr(0),
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disableMobile: true,
        altInputClass: 'form-control read-input',
    })

    flatpickr("#date_de_debut3", {
        minDate: new Date().fp_incr(0),
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disableMobile: true,
        altInputClass: 'form-control read-input',
    })

    $(".notice").delay(6e3).fadeOut(200)
    $(".alert").delay(6e3).fadeOut(200)

    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    function toggleIcon(e) {
        $(e.target)
            .prev('.panel-heading')
            .find(".more-less")
            .toggleClass('fa-chevron-right fa-chevron-down');
    }
    $('.panel-group').on('hidden.bs.collapse', toggleIcon);
    $('.panel-group').on('shown.bs.collapse', toggleIcon);

    // Datatable extends
    $.extend( $.fn.dataTable.defaults, {
        language: {
            processing:     "Traitement en cours...",
            search:         "Rechercher&nbsp;:",
            lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
            info:           "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            infoEmpty:      "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
            infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Pr&eacute;c&eacute;dent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            },
            select: {
                rows: {
                    _: "%d lignes séléctionnées",
                    0: "Aucune ligne séléctionnée",
                    1: "1 ligne séléctionnée"
                } 
            }
        },
        searching:true
    });

    // init on turbolinks load
    if (!$.fn.DataTable.isDataTable("table[id^=dttb-]")) {
        t = $("table[id^=dttb-]").DataTable();
        t.on('order.dt search.dt', function () {
          t.column(0, { search: 'applied', order: 'applied' }).nodes().each(function (cell, i) {
              cell.innerHTML = i + 1;
          });
        }).draw();
    }

    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
            const childrens = document.querySelectorAll('input[readonly]')
            for (const elem of childrens) {
                elem.readOnly = false
            }
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()

                for (const elem of childrens) {
                    elem.readOnly = true
                }
            }
            form.classList.add('was-validated')
        }, false)
    });
    
})

// turbolinks cache fix
document.addEventListener('turbolinks:before-cache', () => {
    var t = $($.fn.dataTable.tables(true)).DataTable();
    if (t != null) {
      t.destroy();
      return t = null;
    }
});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
