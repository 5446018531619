import React from "react"
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/plugins/monthSelect/style.css'

const grades_list = {
    "Terre": {
        "Militaires du Rang":["Soldat de 2eme Classe", "Caporal", "Caporal-Chef"],
        "Sous-officiers subalternes":["Sergent","Sergemt–Chef"],
        "Sous-officiers superieurs":["Adjudant","Adjudant-Chef","Major"],
        "Officiers subalternes":["Aspirant (Ecole)","Sous-Lieutenant","Lieutenant", "Capitaine"],
        "Officiers superieurs":["Commandant ou Chef d’escadrons","Lieutenant-colonel","Colonel"]
    },
    "Air":{
        "Militaires du Rang":["Soldat de 2eme Classe", "Caporal", "Caporal-Chef"],
        "Sous-officiers subalternes":["Sergent","Sergemt–Chef"],
        "Sous-officiers superieurs":["Adjudant","Adjudant-Chef","Major"],
        "Officiers subalternes":["Aspirant (Ecole)","Sous-Lieutenant","Lieutenant", "Capitaine"],
        "Officiers superieurs":["Commandant ou Chef d’escadrons","Lieutenant-colonel","Colonel"],
    },
    "Mer": {
        "Militaires du Rang":["Matelot", "Quartier", "Maitre de 2eme Classe", "Quartier Maitre de 1ere Classe"],
        "Sous-officiers subalternes":["Second Maitre","Maitre"],
        "Sous-officiers superieurs":["Premier Maitre","Maitre Principal","Major"],
        "Officiers subalternes":["Aspirant(Ecole)","Enseigne de Vaisseau de 2eme Classe","Enseigne de Vaisseau de 1ere Classe","Lieutenant de Vaisseau"],
        "Officiers superieurs":["Capitaine de Corvette","Capitaine de Frégate","Capitaine de Vaisseau"]
    },
  "Gendarmerie nationale":{
        "Militaires du Rang":["Eleve gendarme", "Brigadier", "Brigadier Chef"], 
        "Sous-officiers subalternes":["Marechal des Logis", "Marechal des Logis Chef"], 
        "Sous-officiers superieurs":["Adjudant","Adjudant-chef","Major"], 
        "Officiers subalternes":["Aspirant (Ecole)","Sous-lieutenant", "Lieutenant", "Capitaine"],
        "Officiers superieurs":["Commandant ou Chef d’Escadron", "Lieutenant-colonel","Colonnel"]
  }
}

class UpdateUser extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            is_admin: this.props.is_admin || false,
            matricule: this.props.matricule || "",
            salaire_actuel_net: this.props.salaire_actuel_net || 0,
            date_engagement: this.props.date_engagement === undefined || this.props.date_engagement === null || this.props.date_engagement === ''? '': new Date(`${this.props.date_engagement.replace(/ UTC/, '')}`),
            hierarchie: this.props.hierarchie || "",
            corps_armee: this.props.corps_armee || "",
            grade: this.props.grade || "",
            role: this.props.role || '',
            ville: this.props.ville || '',
            banque: this.props.banque || '',
            ville_list: this.props.ville_list,
            
            hierarchies: ["C","B1","B2","A1","A2","A3"],
            corps_armees: ["Terre","Air", "Mer","Gendarmerie nationale"],
            hierarchie_armees: ["Militaires du Rang", "Sous-officiers subalternes", "Sous-officiers superieurs", "Officiers subalternes", "Officiers superieurs"],

            m_defence: false,
            select_hierarchie: [],
            select_grades: []
        }
        this.handlerChangeCorps = this.handlerChangeCorps.bind(this)
        this.handlerChangerHierarchieArmee = this.handlerChangerHierarchieArmee.bind(this)

    }

    componentDidMount(){
        if (this.props.matricule.match('[1-9]{1}[0-9]{4}[\/]{1}[A-Za-z]{1}') !== null) {
            this.setState({m_defence: true})
            if (this.state.corps_armee !== "" && this.state.hierarchie !== "") {
                const hierarchies = grades_list[this.state.corps_armee]
                const select_grades = hierarchies[this.state.hierarchie]

                this.setState({select_grades})
                this.setState({select_hierarchie: hierarchies})
            }
        }
    }

    handlerChangeCorps(e){
        const value = e.target.value

        if (value) {
            const hier_values = grades_list[value];
            this.setState({select_hierarchie: hier_values, select_grades: hier_values[this.state.hierarchie]})
        }
    }

    handlerChangerHierarchieArmee(e){
        const value = e.target.value
        if (value) {
            this.setState({corps_armee: "", select_grades: this.state.select_hierarchie[value]})
        }
    }

    render() {
        const {is_admin, matricule, salaire_actuel_net, date_engagement, hierarchie, select_grades, hierarchie_armees, corps_armee, grade, corps_armees, role, ville, ville_list, banque} = this.state
        const dates = new Date()

        return (
            <>
                <div className="row">
                    <div className="col-sm-6" >
                        <div className="form-group">
                            <label htmlFor="ville">Ville *</label>
                            <select className="form-control" name="ville" defaultValue={ville} required={true}>
                                <option value="">Sélectionner une ville</option>
                                {ville_list.map((v,i)=><option key={i} value={v.nom}>{v.nom}</option>)}
                            </select>
                            <div className="invalid-feedback">La ville d'affectation est obligatoire.</div>
                        </div>
                    </div>
                    <div className="col-sm-6" >
                        <div className="form-group">
                            <label htmlFor="type_user">Type d'utilisateur *</label>
                            <select className="form-control" defaultValue={role} name="role" required={true} onChange={event => this.setState({role: event.target.value})}>
                                <option value="" >Sélectionner un rôle</option>
                                {
                                    this.props.roles.map(ro => <option key={ro.id} value={ro.name}>{ro.name}</option>)
                                }
                            </select>
                        </div>
                    </div>
                </div>
                {
                    is_admin && role === 'Fonctionnaire' &&
                    <>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="matricule">Matricule *</label>
                                    <input type='text' value={matricule} className='form-control' name='matricule' required={true} pattern="[1-9]{1}[0-9]{5}[A-Za-z]{1}|[1-9]{1}[0-9]{4}[\/]{1}[G|g]" onChange={event => this.setState({matricule: event.target.value})}/>
                                    <div className="invalid-feedback">Le matricule est obligatoire.</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="email">Salaire * </label>
                                    <input type="number" name="salaire_actuel_net" value={salaire_actuel_net} className="form-control" required={true} onChange={event => this.setState({salaire_actuel_net: event.target.value.replace(/\D/,'')})} min="440000" max="50000000" />
                                    <div className="invalid-feedback">Le salaire mensuel est obligatoire et doit être compris entre [440.000 - 50.000.000]</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="email">Date d'engagement * </label>
                                    <Flatpickr
                                        options={{
                                            defaultDate: dates,
                                            maxDate: dates.fp_incr(-0),
                                            altInput: true,
                                            altFormat: "j F Y",
                                            dateFormat: "Y-m-d",
                                            disableMobile: true,
                                        }}
                                        className='form-control read-input'
                                        value={date_engagement}
                                        onChange={date_engagement => { this.setState({date_engagement}) }}
                                        required={true}
                                        name="date_engagement"
                                        id="demande_logement_date_engagement"
                                        placeholder="Selectionner une date" />
                                        <div className="invalid-feedback">La date d'engagement est obligatoire.</div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                {this.state.m_defence === false && 
                                    <div className="form-group">
                                        <label htmlFor="email">Hiérarchie * </label>
                                            <select className="form-control" name="hierarchie" required="required" defaultValue={hierarchie}>
                                                <option value="" >Sélectionner une hierarchie</option>
                                                {this.state.hierarchies.map((h,i) => <option key={i} value={h}>{h}</option>)}
                                            </select>
                                        <div className="invalid-feedback">La hiérarchie est obligatoire.</div>
                                    </div>
                                }
                                {this.state.m_defence === true &&
                                    <div className="form-group">
                                        <label htmlFor="corps"> Corps d'armées *</label>
                                        <select className="form-control" name="corps_armee" defaultValue={corps_armee} onChange={this.handlerChangeCorps} required="required">
                                            <option value="">Sélectionner un corps d'armées</option>
                                            {corps_armees.map((c,i)=> <option key={i} value={c}>{c}</option>)}
                                        </select>
                                        <div className="invalid-feedback">Le corps d'armées  est obligatoire.</div>
                                    </div>
                                }
                            </div>
                        </div>
                        {this.state.m_defence === true && 
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="grade">Hiérarchie *</label>
                                        <select name="hierarchie" className="form-control" defaultValue={hierarchie} onChange={this.handlerChangerHierarchieArmee} required="required">
                                            <option value="">Sélectionner une hiérarchie</option>
                                            {hierarchie_armees.map((ha,i)=><option key={i} value={ha}>{ha}</option>)}
                                        </select>
                                        <div className="invalid-feedback">La hiérarchie est obligatoire.</div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="grade">Grade * </label>
                                        <select name="grade" className="form-control" defaultValue={grade} required="required">
                                            <option value="">Sélectionner un garde</option>
                                            {select_grades.map((g,i)=><option key={i} value={g}>{g}</option>)}
                                        </select>
                                        <div className="invalid-feedback">Le grade est obligatoire.</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
                
                <div className="row">
                    {
                        role === 'Banque' &&
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="banque">Banque * </label>
                                <select required={true} className="form-control" id="banque" defaultValue={banque} name="banque" onChange={event => this.setState({banque_actuel: event.target.value})}>
                                <option value="">--- Sélectionner une banque ---</option>
                                {
                                    this.props.banques.map((li, k) => (
                                        <option key={`banque_${k}`} value={li}>{li}</option>
                                    ))
                                }
                                </select>
                                <div className="invalid-feedback">Le nom de la banque est obligatoire.</div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default UpdateUser