import React from "react"
import PropTypes from "prop-types"


class CheckBox extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            cheched: this.props.cheched || false,
        };

    }

    render() {
        const {type_l, habitation_id} = this.props
        const {cheched} = this.state
        return (
            <div className="ml-3 form-check col-md-12">
                <input type="checkbox" name={`model_type_${habitation_id}[]`} id={`model_type_${habitation_id}_${type_l.id}`} checked={cheched} value={type_l.id} className="form-check-input" onChange={() => this.setState({cheched: !this.state.cheched})} />
                <label htmlFor={`model_type_${habitation_id}_${type_l.id}`} className="form-check-label">
                    {type_l.nom}
                </label>
            </div>
        )
    }
}


class Habitation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        cheched: this.props.cheched || false,
    };
  }

  render () {
    const {habitation_id, habitation_nom} = this.props
    const {cheched} = this.state
    return (
        <>
            <div className="ml-3 form-check">
                <input type="checkbox" name="projet[type_logement][]" id={habitation_id} className="form-check-input" value={habitation_id} checked={cheched} onChange={() => this.setState({cheched: !this.state.cheched})}/>
                <label htmlFor={habitation_id} className="form-check-label">
                    {habitation_nom}
                </label>
            </div>
            <hr />
            {
                this.state.cheched &&
                <div className="row shadow p-3 mx-3 bg-white rounded">
                    {
                        this.props.type_logements.map(type_l => (
                            <CheckBox
                                key={type_l.id}
                                type_l={type_l}
                                habitation_id={habitation_id}
                                cheched={this.props.habitation_modele_projets.find((hmp) => hmp.habitation_id === this.props.habitation_id && hmp.type_logement_id === type_l.id)? true : false}
                            />
                        ))
                    }
                </div>
            }
        </>
    );
  }
}

Habitation.propTypes = {
    habitation_id: PropTypes.number,
    habitation_nom: PropTypes.string,
    cheched: PropTypes.bool,
    type_logements: PropTypes.array,
    habitation_modele_projets: PropTypes.array
};

export default Habitation
