import React, {useState} from 'react'
import Select, { components } from 'react-select';

const Menu = props => {
    const optionSelectedLength = props.getValue().length || 0;
    return ( <components.Menu {...props } > 
            {
                optionSelectedLength < 3 ? (props.children) : ( 
                    <div style={{ margin: 15 }}> Vous avez atteint le nombre maximale de choix </div>
                )
            } 
        </components.Menu>
    );
};

const SelectWrap = props => {
    const { defaultValue, required, onChange } = props;
    const isValidNewOption = (inputValue, selectValue) => inputValue.length > 0 && selectValue.length < 3;
    const noOptionsMessage = (inputValue) => "Pas de projet disponible";

    let pardeValue = ""
    for (let index = 0; index < defaultValue.length; index++) {
        pardeValue += defaultValue[index].value
    }
    
    const [valeur, setValeur] = useState(pardeValue)

    const handleChange = (val, act) => {
        if (act.action === 'clear') {
            setValeur("")
        } else {
            if (val === null || val === undefined || val.length === 0) {
                setValeur("")
            } else {
                if (val.length === 1) {
                    setValeur(`${val[0].value}`)
                }
                if (val.length === 2) {
                    setValeur(`${val[0].value}${val[1].value}`)
                }
                if (val.length === 3) {
                    setValeur(`${val[0].value}${val[1].value}${val[2].value}`)
                }
            }
        }
        onChange(val, act)
    }

    return (
        <div className="select-wrapper-container">
            <Select
                {...props}
                components={{Menu}}
                isValidNewOption={isValidNewOption}
                className={`select-wrapper ${valeur? '' : 'input-error'}`}
                noOptionsMessage={noOptionsMessage}
                onChange={handleChange}
                />
                <div className={`invalid-feedback ${valeur? '' : 'd-block'}`} > Le champs projet est obligatoire et peut avoir jusqu'a trois (3) projets. </div>
            <input
                className="input-required"
                type="text"
                value={valeur && JSON.stringify(valeur)}
                tabIndex={-1}
                autoComplete="off" 
                required={required} 
                readOnly={true}
                />
        </div>
    )
}

export default SelectWrap