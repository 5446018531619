import React from "react"
import PropTypes from "prop-types"
class Logement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logement_actuel_acquisition: this.props.logementActuelAcquisition || '',
      habitation_actuel: this.props.habitationActuel || '',
      modele_logement_actuel: this.props.modeleLogementActuel || '',
      surface_habitable_m2_actuel: this.props.surfaceHabitableM2Actuel || 0,
    };

    this.logementActuelAcquisitionChange = this.logementActuelAcquisitionChange.bind(this)
    this.habitationActuelChange = this.habitationActuelChange.bind(this)
    this.modeleLogementActuelChange = this.modeleLogementActuelChange.bind(this);
  }

  logementActuelAcquisitionChange(event) {
    this.setState({logement_actuel_acquisition: event.target.value});
  }

  habitationActuelChange(event) {
    this.setState({habitation_actuel: event.target.value});
  }

  modeleLogementActuelChange(event) {
    this.setState({modele_logement_actuel: event.target.value});
  }

  render () {
    const {logement_actuel_acquisition, habitation_actuel, modele_logement_actuel, surface_habitable_m2_actuel} = this.state
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="logement_actuel_acquisition">Vous souhaitez être accompagné pour une acquisition : Êtes-vous ? * </label>
              <select required={true} className="form-control" defaultValue={logement_actuel_acquisition} name="demande_logement[logement_actuel_acquisition]" id="demande_logement_logement_actuel_acquisition" onChange={this.logementActuelAcquisitionChange}>
                <option value="">--- Sélectionner une situation ---</option>
                <option value="Propriétaire">Propriétaire</option>
                <option value="Locataire">Locataire</option>
                <option value="Hébergé">Hébergé</option>
                <option value="Sans domicile fixe">Sans domicile fixe</option>
              </select>
              <div className="invalid-feedback">La situation de logement est obligatoire.</div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="habitation_actuel">Habitation * </label>
              <select required={true} className="form-control" defaultValue={habitation_actuel} name="demande_logement[habitation_actuel]" id="demande_logement_habitation_actuel" onChange={event => {this.setState({habitation_actuel: event.target.value})}}>
                <option value="">--- Sélectionner une habitation ---</option>
                <option value="Appartement">Appartement</option>
                <option value="Villa/Maison">Villa/Maison</option>
              </select>
              <div className="invalid-feedback">L'habitation actuel est obligatoire.</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="modele_logement_actuel">Modèle de logement * </label>
              <select required={true} className="form-control" defaultValue={modele_logement_actuel} name="demande_logement[modele_logement_actuel]" id="demande_logement_modele_logement_actuel" onChange={this.modeleLogementActuelChange}>
                <option value="">--- Sélectionner un modèle ---</option>
                {
                  this.props.logement_actuels.map((loge, i) => (
                    <option key={`logement-${i}`} value={loge}> {loge} </option>
                  ))
                }
                <option value="Autre">Autre</option>
              </select>
              <div className="invalid-feedback">Le modèle de logement actuel est obligatoire.</div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email">Surface habitable en m2 </label>
              <input type="number" min='0' max="10000" name="demande_logement[surface_habitable_m2_actuel]" value={surface_habitable_m2_actuel} className="form-control" onChange={event => {this.setState({surface_habitable_m2_actuel: event.target.value.replace(/\D/,'')})}} />
              <div className="invalid-feedback">La surface habitable actuel doit être comprise entre [0-10000].</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Logement.propTypes = {
  logementActuelAcquisition: PropTypes.string,
  habitationActuel: PropTypes.string,
  modeleLogementActuel: PropTypes.string,
  surfaceHabitableM2Actuel: PropTypes.number
};
export default Logement
