import React from "react"
import PropTypes from "prop-types"
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css'

class SalaireInformel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salaire_actuel_net: this.props.salaireActuelNet || 0,
      avec_compte_informel: this.props.avec_compte_informel || '',
      banque_actuel: this.props.banqueActuel || '',
      anciennete_compte: this.props.ancienneteCompte === null || this.props.ancienneteCompte === ''? '': new Date(`${this.props.ancienneteCompte.replace(/ UTC/, '')}`),
      relation_avec_banque: this.props.relationAvecBanque? 'Oui': 'Non',
      banque_pour_relation: this.props.banquePourRelation || '',
      error: false
    };

    this.AvecCompteInformelChange = this.AvecCompteInformelChange.bind(this)
  }

  AvecCompteInformelChange(event) {
   
    this.setState({avec_compte_informel: event.target.value});
  }

  render () {
    const {salaire_actuel_net, avec_compte_informel, banque_actuel, anciennete_compte, relation_avec_banque, banque_pour_relation} = this.state
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="email">Votre chiffre d'affaire (GNF) * </label>
              <input type="number" name="demande_logement[salaire_actuel_net]" value={salaire_actuel_net} className="form-control" required={true} onChange={event => this.setState({salaire_actuel_net: event.target.value.replace(/\D/,'')})} max="2000000000" />
              <div className="invalid-feedback">Le chiffre d'affaire est obligatoire et doit être un maximum de 2.000.000.000</div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <label htmlFor="email">Avez-vous un compte bancaire *</label>
              <div className="col-sm-12">
                <div className="custom-control custom-radio form-check-inline">
                  <input id="compte_informel_oui" type="radio" name="demande_logement[avec_compte_informel]" value="Oui" onChange={this.AvecCompteInformelChange} checked={avec_compte_informel==="Oui"} className="custom-control-input"  required={true}/>
                  <label htmlFor="compte_informel_oui" className="custom-control-label">Oui </label>
                </div>
                <div className="custom-control custom-radio form-check-inline">
                  <input id="compte_informel_non" type="radio" name="demande_logement[avec_compte_informel]" value="Non" onChange={this.AvecCompteInformelChange} checked={avec_compte_informel==="Non"} className="custom-control-input" required={true} />
                  <label htmlFor="compte_informel_non" className="custom-control-label">Non </label>
                  <div className="invalid-feedback">Ce champ est obligatoire.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          avec_compte_informel === 'Oui' &&
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Banque * </label>
                <select required={true} className="form-control" defaultValue={banque_actuel} name="demande_logement[banque_actuel]" onChange={event => this.setState({banque_actuel: event.target.value})}>
                  <option value="">--- Sélectionner une banque ---</option>
                  {
                    this.props.banques.map((li, k) => (
                      <option key={`banque_sal_${k}`} value={li}>{li}</option>
                    ))
                  }
                </select>
                <div className="invalid-feedback">Le nom de la banque est obligatoire.</div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <label htmlFor="email">Date d’ouverture du compte </label>
                <Flatpickr
                  options={{
                    maxDate: new Date().fp_incr(-0),
                    altInput: true,
                    altFormat: "F Y",
                    dateFormat: "Y-m-d",
                    disableMobile: true,
                    plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "Y-m-d", altFormat: "F Y"})],
                  }}
                  className='form-control read-input'
                  value={anciennete_compte}
                  onChange={anciennete_compte => { this.setState({anciennete_compte}) }}
                  name="demande_logement[anciennete_compte]"
                  id="demande_logement_anciennete_compte"
                  placeholder="Selectionner une date" />
                  <div className="invalid-feedback">La date d’ouverture du compte est obligatoire.</div>
              </div>
            </div>
          </div>
        }
        {
          avec_compte_informel === 'Non' &&
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <label htmlFor="email">Souhaitez-vous être mis en relation avec une banque ? *</label>
                <div className="col-sm-12">
                  <div className="form-check form-check-inline">
                    <input id="oui" type="radio" name="demande_logement[relation_avec_banque]" value="Oui" onChange={event => this.setState({relation_avec_banque: event.target.value})} defaultChecked={relation_avec_banque==="Oui"} className="form-check-input" required={true} />
                    <label htmlFor="oui" className="form-check-label">Oui </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input id="non" type="radio" name="demande_logement[relation_avec_banque]" value="Non" onChange={event => this.setState({relation_avec_banque: event.target.value})} defaultChecked={relation_avec_banque==="Non"} className="form-check-input" required={true} />
                    <label htmlFor="non" className="form-check-label">Non </label>
                  </div>
                </div>
              </div>
            </div>

            {
              relation_avec_banque === 'Oui' &&
              <div className="col-sm-6">
                <div className="row">
                  <label htmlFor="email">Si oui, laquelle ? *</label>
                  <select defaultValue={banque_pour_relation} name="demande_logement[banque_pour_relation]" className="form-control" onChange={ event => this.setState({banque_pour_relation: event.target.value}) }>
                    <option value="">--- Sélectionner une banque ---</option>
                      {
                        this.props.banques.map((li, k) => (
                          <option key={`banque_sal_rel_${k}`} value={li}>{li}</option>
                        ))
                      }
                  </select>
                </div>
              </div>
            }
          </div>
        }
      </React.Fragment>
    );
  }
}

SalaireInformel.propTypes = {
  salaireActuelNet: PropTypes.number,
  avec_compte_informel: PropTypes.string,
  banqueActuel: PropTypes.string,
  ancienneteCompte: PropTypes.node,
  relationAvecBanque: PropTypes.bool,
  banquePourRelation: PropTypes.string
};
export default SalaireInformel
