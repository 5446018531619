import React from "react"
import PropTypes from "prop-types"
class Projet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      source_information: this.props.sourceInformation || '',
      note_plateforme: this.props.notePlateforme || "Pas d'avis",
      note_commentaire: this.props.noteCommentaire || '',
    };

    this.selectSourceInformationChange = this.selectSourceInformationChange.bind(this)
  }

  selectSourceInformationChange(event) {
    this.setState({habitation_actuel: event.target.value});
  }


  render () {
    const {source_information, note_plateforme, note_commentaire} = this.state
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="source_information">Par quel canal avez-vous entendu parler du projet ? * </label>
              <select required={true} className="form-control" defaultValue={source_information} name="demande_logement[source_information]" id="demande_logement_source_information" onChange={this.selectSourceInformationChange}>
                <option value="Bouche à oreille">Bouche à oreille</option>
                <option value="Mail">Mail</option>
                <option value="Réseaux sociaux">Réseaux sociaux</option>
                <option value="Radio">Radio</option>
                <option value="TV">TV</option>
              </select>
              <div className="invalid-feedback">La source d'information du projet est obligatoire.</div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="select_note_plateforme">Notez la simplicité de la plateforme </label>
              <select  className="form-control" defaultValue={note_plateforme} name="demande_logement[note_plateforme]" id="select_note_plateforme" onChange={event => this.setState({note_plateforme: event.target.value})}>
                <option value="Très Compliqué">Très Compliqué</option>
                <option value="Compliqué">Compliqué</option>
                <option value="Passable">Passable</option>
                <option value="Simple">Simple</option>
                <option value="Très Simple">Très Simple</option>
                <option value="Pas d'avis">Pas d'avis</option>
              </select>
            </div>
          </div>
        </div>
        {
          note_plateforme !== "Pas d'avis" &&
          <div className="row">
            <div className="col-sm-6">
                <div className="form-group">
                  <label>Donnez votre avis par rapport à la simplicité du formulaire </label>
                  <textarea className="form-control" name="demande_logement[note_commentaire]" placeholder="Donnez votre avis par rapport à la simplicité du formulaire" defaultValue={note_commentaire}></textarea>
                </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

Projet.propTypes = {
  etiezVousInformez: PropTypes.string,
  sourceInformation: PropTypes.string,
  notePlateforme: PropTypes.string,
  noteCommentaire: PropTypes.string
};
export default Projet
