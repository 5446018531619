import React from "react"
import PropTypes from "prop-types"
import $ from 'jquery'


class ProjetUpdateType extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            type_details: this.props.type_details,
        }

    }

    render() {
        return (
            <div className="shadow p-3 mb-5 bg-white rounded">
                <label className="font-weight-bold pb-2">{this.props.type_logement_nom}</label>
                <input type="hidden" name={`projet_ids[]`} value={this.props.type_details.id} onChange={() => {}} />

                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor={`projet_prix_${this.props.type_details.id}`}>Prix</label>
                        <input id={`projet_prix_${this.props.type_details.id}`} type="number" name={`projet_prix_${this.props.type_details.id}`} value={this.state.type_details.prix} className="form-control" required={true} min="1" max="10000000000" placeholder="prix" onChange={(e) => this.setState({type_details: {...this.state.type_details, prix: e.target.value}}) } />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor={`projet_toilette_${this.props.type_details.id}`}>Nombre de toilette</label>
                        <input id={`projet_toilette_${this.props.type_details.id}`} type="number" name={`projet_toilette_${this.props.type_details.id}`} value={this.state.type_details.toilette} className="form-control" required={true} min="1" placeholder="nombre de toilette interne" onChange={(e) => this.setState({type_details: {...this.state.type_details, toilette: e.target.value}}) } />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor={`projet_cuisine_${this.props.type_details.id}`}>Nombre de cuisine</label>
                        <input id={`projet_cuisine_${this.props.type_details.id}`} type="number" name={`projet_cuisine_${this.props.type_details.id}`} value={this.state.type_details.cuisine} className="form-control" required={true} min="1" placeholder="nombre de cuisine" onChange={(e) => this.setState({type_details: {...this.state.type_details, cuisine: e.target.value}}) } />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor={`projet_superficie_${this.props.type_details.id}`}>Superficie en m<sup>2</sup></label>
                        <input id={`projet_superficie_${this.props.type_details.id}`} type="number" name={`projet_superficie_${this.props.type_details.id}`} value={this.state.type_details.superficie} className="form-control" required={true} min="1" placeholder="superficie" onChange={(e) => this.setState({type_details: {...this.state.type_details, superficie: e.target.value}}) } />
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor={`projet_standing_${this.props.type_details.id}`}>Standing</label>
                        <select className="form-control" defaultValue={this.props.type_details.standing_type} required={true} onChange={() => {}} id={`projet_standing_${this.props.type_details.id}`} name={`projet_standing_${this.props.type_details.id}`}>
                            <option value="Standing Normal" >Standing Normal</option>
                            <option value="Moyens Standing" >Moyens Standing</option>
                            <option value="Haut Standing" >Haut Standing</option>
                            <option value="Très Haut Standing" >Très Haut Standing</option>
                        </select>
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor={`projet_caractere_${this.props.type_details.id}`}>Caractere</label>
                        <select className="form-control" defaultValue={this.props.type_details.caractere} required={true} onChange={() => {}} id={`projet_caractere_${this.props.type_details.id}`} name={`projet_caractere_${this.props.type_details.id}`}>
                            <option value="Social">Social</option>
                            <option value="Economique">Economique</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor={`projet_images_${this.props.type_details.id}`}>Images</label>
                        <input id={`projet_images_${this.props.type_details.id}`} type="file" name={`projet_images_${this.props.type_details.id}`} className="form-control" required={'required'} multiple={true} accept="image/jpeg, image/png" placeholder="images" />
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor={`projet_plans_${this.props.type_details.id}`}>Plans</label>
                        <input id={`projet_plans_${this.props.type_details.id}`} type="file" name={`projet_plans_${this.props.type_details.id}`} className="form-control" required={'required'} multiple={true} accept="image/jpeg, image/png" placeholder="plans" />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor={`projet_pdfs_${this.props.type_details.id}`}>Pdfs</label>
                        <input id={`projet_pdfs_${this.props.type_details.id}`} type="file" name={`projet_pdfs_${this.props.type_details.id}`} className="form-control" multiple={'required'} accept="application/pdf" placeholder="pdfs" />
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor={`projet_video_${this.props.type_details.id}`}>Video</label>
                        <input id={`projet_video_${this.props.type_details.id}`} type="file" name={`projet_video_${this.props.type_details.id}`} className="form-control" required={'required'} accept="video/x-msvideo, video/mp4, video/mpeg, video/webm" placeholder="video" />
                    </div>
                </div>
            </div>
        )
    }
}

ProjetUpdateType.propTypes = {
    type_details: PropTypes.object,
};

export default ProjetUpdateType
